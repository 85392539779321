export class FinancialPaths {
    public static accounting: string = 'financial/accounting/v1';
    public static audit: string = 'financial/audit/v1';
    public static auditReport: string = 'financial/audit-reports/v1';
    public static bankAccount: string = 'financial/bank-account/v1';
    public static bankAccountHistory: string = 'financial/report/v1';
    public static bankAccountTransfer: string = 'financial/bank-account-transfer/v1';
    public static bank: string = 'financial/bank/v1';
    public static bankSlipConfiguration: string = 'financial/bank-slip-configuration/v1';
    public static billConciliation: string = 'financial/bill-conciliation';
    public static bill: string = 'financial/bill/v1';
    public static billInstallment: string = 'financial/bill-installment';
    public static billInstallmentReport: string = 'financial/report/bill-installment/v1';
    public static billet: string = 'financial/billet';
    public static cancelPayment: string = 'financial/cancel-payment/v1';
    public static cardAdministrator: string = 'financial/card-administrator/v1';
    public static cashFlow: string = 'financial/cash-flow/v1';
    public static cashier: string = 'financial/cashier/v1';
    public static cashierShift: string = 'financial/cashier-shift/v1';
    public static chargeType: string = 'financial/charge-type';
    public static checkIssued: string = 'financial/check-issued';
    public static checkReceived: string = 'financial/check-received';
    public static city: string = 'financial/city/v1';
    public static client: string = 'financial/client';
    public static company: string = 'financial/company';
    public static configuration: string = 'financial/configuration/v1';
    public static costCenterAssessment: string = 'financial/cost-center-assessment/v1';
    public static costCenterAssessmentCostCenter: string = 'financial/cost-center-assessment-cost-center/v1';
    public static costCenter: string = 'financial/cost-center/v1';
    public static country: string = 'financial/country/v1';
    public static creditClient: string = 'financial/credit-client/v1';
    public static custody: string = 'financial/custody/v1';
    public static devolution: string = 'financial/devolution/v1';
    public static dreReport: string = 'financial/report/dre/v1';
    public static employee: string = 'financial/employee/v1';
    public static file: string = 'financial/file';
    public static flagCard: string = 'financial/flag-card/v1';
    public static health: string = 'financial/public/check/health';
    public static lineOfBusiness: string = 'financial/line-of-business/v1';
    public static paymentCheckIssued: string = 'financial/payment-check-issued/v1';
    public static paymentCheckReceived: string = 'financial/payment-check-received';
    public static payment: string = 'financial/payment/v1';
    public static paymentMethod: string = 'financial/payment-method/v1';
    public static phoneType: string = 'financial/phone-type/v1';
    public static postingCategory: string = 'financial/posting-category/v1';
    public static product: string = 'financial/product/v1';
    public static promissoryNoteReport: string = 'financial/report/promissory-note/v1';
    public static provider: string = 'financial/provider';
    public static reasonDevolution: string = 'financial/reason-devolution/v1';
    public static reasonRefund: string = 'financial/reason-refund/v1';
    public static receivementCard: string = 'financial/receivement-card';
    public static receivement: string = 'financial/receivement';
    public static receivementReport: string = 'financial/report/receivement-report/v1';
    public static sale: string = 'financial/sale';
    public static socialNetworkType: string = 'financial/social-network-type/v1';
    public static state: string = 'financial/state/v1';
    public static transactionConciliation: string = 'financial/transaction-conciliation';
    public static unitMeasurement: string = 'financial/unit-measurement/v1';
}
