import { FinancialReceivementCardType } from '@gipi-financial/card-administrator/enums/receivement-card-type.enum';
import { FinancialTypeChargeType } from '@gipi-financial/charge-type/enums/charge-type.enum';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { FinancialFlagCard } from '@gipi-financial/flag-card/models/flag-card.model';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialCardAdministrator } from '../card-administrador.model';

export class FinancialReceivementCardFilterDTO extends GIPIBaseAbstractFilterModel {

    amount: number;

    begin: Date;

    /** @Transient */
    cardAdministrator: FinancialCardAdministrator;
    cardAdministratorId: number;

    /** @Transient */
    chargeType: FinancialChargeType;
    chargeTypeId: number;

    concilied: boolean;

    documentNumberOrigin: string;

    end: Date;

    /** @Transient */
    flagCard: FinancialFlagCard;
    flagCardId: number;

    receivementCardIdList: GIPIUuid[];

    receivementStatusList: FinancialReceivementCardType[];

    typeList: FinancialTypeChargeType[];

}
