import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RegistrationBenefitClubService } from '@gipi-registration/benefit-club/services/benefit-club.service';
import { RegistrationClientClassification } from '@gipi-registration/client-classification/models/client-classification.model';
import { RegistrationClientClassificationService } from '@gipi-registration/client-classification/services/client-classification.service';
import { RegistrationClientService } from '@gipi-registration/client/services/client.service';
import { RegistrationConnectConfigService } from '@gipi-registration/connect-config/services/connect-config.service';
import { ConnectConsultDialogComponent } from '@gipi-registration/connect-consult/components/connect-consult-dialog/connect-consult-dialog.component';
import { GIPIClientSheetCnpjDialogComponent } from '@gipi-registration/consult-cnpj/components/client-sheet-cnpj-dialog/client-sheet-cnpj-dialog.component';
import { RegistrationConsultCnpjService } from '@gipi-registration/consult-cnpj/services/consult-cnpj.service';
import { RegistrationLineOfBusinessService } from '@gipi-registration/line-of-business/services/line-of-business.service';
import { RegistrationLegalPersonTypeEnum } from '@gipi-registration/person/enums/legal-person-type.enum';
import { RegistrationAddress } from '@gipi-registration/person/models/address.model';
import { RegistrationPersonConsultDTO } from '@gipi-registration/person/models/dto/person-consult.dto';
import { RegistrationLegalPerson } from '@gipi-registration/person/models/legal-person.model';
import { RegistrationNaturalPerson } from '@gipi-registration/person/models/natural-person.model';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { RegistrationProviderService } from '@gipi-registration/provider/services/provider.service';
import { OptionYesNoEnum } from '@gipi-shared/enums/options-yes-no.enum';
import { TypePersonEnum } from '@gipi-shared/enums/type-person.enum';
import { APP_MESSAGES, ArrayUtil, DocumentUtil, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { PersonAssociationDialogComponent } from '../person-association-dialog/person-association-dialog.component';
import { PersonListDialogComponent } from '../person-list-dialog/person-list-dialog.component';
export class PersonFormComponent extends GIPIAbstractComponent {
    constructor(baseService, activatedRoute, changeDetectorRef, router, _clientService, _providerService, _lineOfBusinessService, _consultCnpjService, _clientClassificationService, _benefitClubService, _connectConfigService) {
        super(baseService, activatedRoute);
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this.changeDetectorRef = changeDetectorRef;
        this.router = router;
        this._clientService = _clientService;
        this._providerService = _providerService;
        this._lineOfBusinessService = _lineOfBusinessService;
        this._consultCnpjService = _consultCnpjService;
        this._clientClassificationService = _clientClassificationService;
        this._benefitClubService = _benefitClubService;
        this._connectConfigService = _connectConfigService;
        this.typePersonEnum = TypePersonEnum;
        this.legalPersonTypeEnum = RegistrationLegalPersonTypeEnum;
        this.optionYesNoEnum = OptionYesNoEnum;
        this.minDate = new Date(1900, 0, 1, 0, 0, 0);
        this._connectConfigEnabled$ = new BehaviorSubject(false);
        this._clientList = [];
        this._providerList = [];
        /** Mudou o lineOfBusiness para a entidade da empresa */
        this._lineOfBusinessList = [];
        this._optionsBenefitClubList = [];
        this.disabled = false;
        this._ruralProducer = false;
        this._icmsTaxPayer = false;
        this._personBlocked = 'NO';
        this._controlClientLimit = 'NO';
        this._personEnabled = 'YES';
        this._commentsClient = '';
        this._personValue = new RegistrationPerson();
        this.ruralProducerChange = new EventEmitter();
        this.icmsTaxPayerChange = new EventEmitter();
        this.personBlockedChange = new EventEmitter();
        this.controlClientLimitChange = new EventEmitter();
        this.personEnabledChange = new EventEmitter();
        this.commentsClientChange = new EventEmitter();
        this.personChange = new EventEmitter();
        this.entityChange = new EventEmitter();
        this.benefitClubListChange = new EventEmitter();
        this.clientClassificationChange = new EventEmitter();
        this.findCnpjOrCpfChange = new EventEmitter();
        this.clientClassificationFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._clientClassificationService.findByValue(value, page, 10, new GIPISortModel('description', 'ASC')).toPromise();
            return result;
        });
        setTimeout(() => {
            if (ObjectUtil.isNull(this.person)) {
                this.person = new RegistrationPerson();
            }
            if (ObjectUtil.isNull(this.person.legalPerson)) {
                this.person.legalPerson = new RegistrationLegalPerson();
                // Mudou o lineOfBusiness para a entidade da empresa
                // if (this.typeRegister === 'COMPANY') {
                //     this.person.legalPerson.lineOfBusiness = new RegistrationLineOfBusiness();
                // }
            }
            if (ObjectUtil.isNull(this.person.naturalPerson)) {
                this.person.naturalPerson = new RegistrationNaturalPerson();
            }
        });
    }
    get ruralProducer() {
        return this._ruralProducer;
    }
    set ruralProducer(value) {
        this._ruralProducer = value;
        this.ruralProducerChange.emit(value);
    }
    get icmsTaxPayer() {
        return this._icmsTaxPayer;
    }
    set icmsTaxPayer(value) {
        this._icmsTaxPayer = value;
        this.icmsTaxPayerChange.emit(value);
    }
    get personBlocked() {
        return this._personBlocked;
    }
    set personBlocked(value) {
        this._personBlocked = value;
        this.personBlockedChange.emit(value);
    }
    get controlClientLimit() {
        return this._controlClientLimit;
    }
    set controlClientLimit(value) {
        this._controlClientLimit = value;
        this.controlClientLimitChange.emit(value);
    }
    get personEnabled() {
        return this._personEnabled;
    }
    set personEnabled(value) {
        this._personEnabled = value;
        this.personEnabledChange.emit(value);
    }
    get commentsClient() {
        return this._commentsClient;
    }
    set commentsClient(value) {
        this._commentsClient = value;
        this.commentsClientChange.emit(value);
    }
    get person() {
        return this._personValue;
    }
    set person(person) {
        this._personValue = person;
        if (!ObjectUtil.isNull(this._personValue.legalPerson)) {
            this._personValue.typePerson = 'LEGAL_PERSON';
        }
        else {
            this._personValue.typePerson = 'NATURAL_PERSON';
        }
        this.personChange.emit(this._personValue);
    }
    get entity() {
        return this.entityValue;
    }
    set entity(entity) {
        this.entityValue = entity;
        this.entityChange.emit(this.entityValue);
    }
    get benefitClubList() {
        return this._benefitClubList;
    }
    set benefitClubList(value) {
        this._benefitClubList = value;
        this.benefitClubListChange.emit(this.benefitClubList);
    }
    get clientClassification() {
        return this._clientClassification;
    }
    set clientClassification(value) {
        this._clientClassification = value;
        this.clientClassificationChange.emit(this.clientClassification);
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            _super.ngOnInit.call(this);
            // Mudou o lineOfBusiness para a entidade da empresa
            // if (this.typeRegister === 'COMPANY') {
            //     this._findLineOfBusiness();
            // } else
            if (this.typeRegister === 'CLIENT') {
                this._findBenefitClubList();
                this._findConnectConfig();
                this._connectConfigService.updated$.subscribe(isUpdated => {
                    if (isUpdated) {
                        this._findConnectConfig();
                    }
                });
            }
        });
    }
    _findLineOfBusiness() {
        try {
            // Mudou o lineOfBusiness para a entidade da empresa
            this._lineOfBusinessList = [];
            // this._lineOfBusinessService.findAllEnabled(0, 50).toPromise().then(page => {
            //     if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
            //     }
            // }, error => {
            //     throw new Error(error);
            // });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    _findBenefitClubList() {
        try {
            this._benefitClubService.findByValue('', 0, 100, new GIPISortModel('description', 'ASC')).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this._optionsBenefitClubList = [...page.content];
                }
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    _findConnectConfig() {
        try {
            if (ObjectUtil.isNull(this._connectConfigEnabled$)) {
                this._connectConfigEnabled$ = new BehaviorSubject(false);
            }
            this._connectConfigService.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this._connectConfigEnabled$.next(page.content[0].enabled);
                }
            }, error => {
                throw new Error(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    _showDialogPersonList(entityList) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const personListData = {
                    entityList: [...entityList],
                    titleDialog: (this.typeRegister === 'CLIENT') ? 'Selecione um cliente' : 'Selecione um fornecedor',
                };
                const personSelected = yield this.baseService.dialogService.open({
                    componentOrTemplateRef: PersonListDialogComponent,
                    data: personListData,
                    width: '60%',
                    height: '63%'
                }).afterClosed().toPromise();
                if (ObjectUtil.isNull(personSelected)) {
                    const resolved = yield this._verifyCreateOrUpdateRegister();
                    if (resolved) {
                        return;
                    }
                }
                return Promise.resolve(personSelected.id);
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
                return Promise.resolve(null);
            }
        });
    }
    _showDialogPersonAssociation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let typeDocument = 'documento';
            if (!ObjectUtil.isNull(this.person)) {
                if (this.person.typePerson === 'NATURAL_PERSON') {
                    typeDocument = 'CPF';
                }
                else if (this.person.typePerson === 'LEGAL_PERSON') {
                    typeDocument = 'CNPJ';
                }
            }
            const personAssociationData = {
                title: (this.typeRegister === 'CLIENT') ? 'Clientes associados' : 'Fornecedores associados',
                message: `Identificamos que existem registros associados a este ${typeDocument}. Deseja atualizar as informações desses registros ou criar um novo?`,
            };
            const typeOperation = yield this.baseService.dialogService.open({
                componentOrTemplateRef: PersonAssociationDialogComponent,
                data: personAssociationData,
                width: '20%',
            }).afterClosed().toPromise();
            return typeOperation;
        });
    }
    _redirectRegisterToEdit(id, permission) {
        if (StringUtil.isEmpty(permission) || !this.hasPermission(permission)) {
            this.addWarningMessage(INJECTOR.get(APP_MESSAGES).NOT_PERMISSION);
            return;
        }
        if (!this.UUIDIsValid(id)) {
            return;
        }
        const pathEdit = (this.typeRegister === 'CLIENT') ? 'financial/registers/clients/clients' : 'financial/registers/providers';
        this.router.navigateByUrl(pathEdit, { skipLocationChange: true }).then(() => this.router.navigate([pathEdit, 'edit', id]));
    }
    _verifyCreateOrUpdateRegister() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const entityList = (this.typeRegister === 'CLIENT') ? [...this._clientList] : [...this._providerList];
                if (ArrayUtil.isEmpty(entityList)) {
                    return Promise.resolve(false);
                }
                const typeOperation = yield this._showDialogPersonAssociation();
                // Se clicou em novo, ele volta e consulta pela receita, parando essa função aqui mesmo.
                if (typeOperation === 'NEW_RECORD') {
                    return Promise.resolve(false);
                }
                else if (typeOperation === 'NONE') {
                    // Se retornou 'NONE' é porque clicou em fechar o modal, então retorna true como se fosse resolvido para que o processo de consulta do
                    // CNPJ se encerre.
                    return Promise.resolve(true);
                }
                const permissionUpdate = (this.typeRegister === 'CLIENT') ? 'CLIENT_UPDATE' : 'PROVIDER_UPDATE';
                if (entityList.length > 1) {
                    const personList = entityList.map(e => RegistrationPersonConsultDTO.toPersonDTO(e));
                    const idSelected = yield this._showDialogPersonList(personList);
                    this._redirectRegisterToEdit(idSelected, permissionUpdate);
                }
                else {
                    this._redirectRegisterToEdit(entityList[0].id, permissionUpdate);
                }
                return Promise.resolve(true);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    _handleRegister() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (ObjectUtil.isNull(this.person)) {
                    return Promise.resolve(false);
                }
                if ((this.typeRegister !== 'CLIENT') && (this.typeRegister !== 'PROVIDER')) {
                    return Promise.resolve(false);
                }
                const cpfOrCnpj = (this.person.typePerson === 'NATURAL_PERSON') ? this.person.naturalPerson.cpf : this.person.legalPerson.cnpj;
                if (StringUtil.isEmpty(cpfOrCnpj)) {
                    return Promise.resolve(false);
                }
                const entityList = ((this.typeRegister === 'CLIENT')
                    ? yield this._clientService.findByCpfOrCnpj(cpfOrCnpj).toPromise()
                    : yield this._providerService.findByCpfOrCnpj(cpfOrCnpj).toPromise());
                this._clientList = [];
                this._providerList = [];
                if (!ArrayUtil.isEmpty(entityList)) {
                    if (this.typeRegister === 'CLIENT') {
                        this._clientList = ArrayUtil.clone(entityList);
                    }
                    else if (this.typeRegister === 'PROVIDER') {
                        this._providerList = ArrayUtil.clone(entityList);
                    }
                    return this._verifyCreateOrUpdateRegister();
                }
                return Promise.resolve(false);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    consultCnpj(isBlur = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if ((isBlur && StringUtil.isEmpty(this.person.legalPerson.cnpj)) ||
                    this.disabled ||
                    this.loading ||
                    this.isEditing() ||
                    this.isViewing() ||
                    !this.hasPermissionForOperation() ||
                    ((this.typeRegister !== 'CLIENT') && (this.typeRegister !== 'PROVIDER') && (this.typeRegister !== 'REPRESENTATIVE_PROVIDER'))) {
                    return;
                }
                if (!isBlur && StringUtil.isEmpty(this.person.legalPerson.cnpj)) {
                    this.addWarningMessage('Preencha o campo para realizar a consulta');
                    return;
                }
                if (!DocumentUtil.isValidCnpj(this.person.legalPerson.cnpj)) {
                    this.addWarningMessage('CNPJ informado é inválido');
                    return;
                }
                this.loading = true;
                if ((this.typeRegister === 'CLIENT') || (this.typeRegister === 'PROVIDER')) {
                    const registerValidated = yield this._handleRegister().catch(() => this.loading = false);
                    // Se foi validado é porque, foi escolhido um cliente ou fornecedor pra ser atualizado, então para a função aqui.
                    if (registerValidated) {
                        this.loading = false;
                        this.findCnpjOrCpfChange.emit();
                        return;
                    }
                }
                yield this._setPersonConsultedByCnpjInPerson();
            }
            catch (e) {
                this.handleError(e);
            }
        });
    }
    consultCpf() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (StringUtil.isEmpty(this.person.naturalPerson.cpf)) {
                    return;
                }
                if (this.disabled ||
                    this.loading ||
                    this.isEditing() ||
                    this.isViewing() ||
                    StringUtil.isEmpty(this.person.naturalPerson.cpf) ||
                    !this.hasPermissionForOperation() ||
                    ((this.typeRegister !== 'CLIENT') && (this.typeRegister !== 'PROVIDER'))) {
                    this.addErrorMessage('Ocorreu um erro ao consulta o CPF');
                    return;
                }
                if (!DocumentUtil.isValidCpf(this.person.naturalPerson.cpf)) {
                    this.addWarningMessage('CPF informado é inválido');
                    return;
                }
                this.loading = true;
                if ((this.typeRegister === 'CLIENT') || (this.typeRegister === 'PROVIDER')) {
                    const registerValidated = yield this._handleRegister().catch(() => this.loading = false);
                    // Se foi validado é porque, foi escolhido um cliente ou fornecedor pra ser atualizado, então para a função aqui.
                    if (registerValidated) {
                        this.loading = false;
                        this.findCnpjOrCpfChange.emit();
                        return;
                    }
                    else {
                        this.loading = false;
                        this.findCnpjOrCpfChange.emit();
                    }
                }
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    _setPersonConsultedByCnpjInPerson() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                this._personConsultedByCnpj = yield this._consultCnpjService.consultCnpj(this.person.legalPerson.cnpj).toPromise();
                if (ObjectUtil.isNull(this._personConsultedByCnpj)) {
                    this.addWarningMessage('Nenhum registro foi encontrado');
                    this.loading = false;
                    return Promise.resolve(false);
                }
                this.person.name = !StringUtil.isEmpty(this._personConsultedByCnpj.name) ? this._personConsultedByCnpj.name : '';
                this.person.fantasyName = !StringUtil.isEmpty(this._personConsultedByCnpj.fantasyName) ? this._personConsultedByCnpj.fantasyName : this._personConsultedByCnpj.name;
                this.person.birthDate = this._personConsultedByCnpj.birthDate;
                if (ObjectUtil.isNull(this.person.legalPerson)) {
                    this.person.legalPerson = new RegistrationLegalPerson();
                }
                this.person.legalPerson.enabled = this._personConsultedByCnpj.legalPerson.enabled;
                this.person.legalPerson.cnae = this._personConsultedByCnpj.legalPerson.cnae;
                this.person.legalPerson.stateRegistration = this._personConsultedByCnpj.legalPerson.stateRegistration;
                this.person.legalPerson.municipalRegistration = this._personConsultedByCnpj.legalPerson.municipalRegistration;
                this.person.legalPerson.type = this._personConsultedByCnpj.legalPerson.type;
                if (!ArrayUtil.isEmpty(this._personConsultedByCnpj.addressList)) {
                    if (!ArrayUtil.isEmpty(this.person.addressList)) {
                        this.person.addressList.filter(a => a.useInInvoice).map(addressDefault => {
                            addressDefault.enabled = this._personConsultedByCnpj.addressList[0].enabled;
                            addressDefault.city = this._personConsultedByCnpj.addressList[0].city;
                            addressDefault.state = this._personConsultedByCnpj.addressList[0].city.state;
                            addressDefault.country = this._personConsultedByCnpj.addressList[0].city.state.country;
                            addressDefault.cityList = [this._personConsultedByCnpj.addressList[0].city];
                            addressDefault.complement = this._personConsultedByCnpj.addressList[0].complement;
                            addressDefault.neighborhood = this._personConsultedByCnpj.addressList[0].neighborhood;
                            addressDefault.street = this._personConsultedByCnpj.addressList[0].street;
                            addressDefault.streetNumber = this._personConsultedByCnpj.addressList[0].streetNumber;
                            addressDefault.type = 'RESIDENTIAL';
                            addressDefault.useInInvoice = this._personConsultedByCnpj.addressList[0].useInInvoice;
                            addressDefault.zipCode = this._personConsultedByCnpj.addressList[0].zipCode;
                        });
                    }
                    else {
                        const addressConsulted = new RegistrationAddress();
                        addressConsulted.enabled = this._personConsultedByCnpj.addressList[0].enabled;
                        addressConsulted.city = this._personConsultedByCnpj.addressList[0].city;
                        addressConsulted.state = this._personConsultedByCnpj.addressList[0].city.state;
                        addressConsulted.country = this._personConsultedByCnpj.addressList[0].city.state.country;
                        addressConsulted.cityList = [this._personConsultedByCnpj.addressList[0].city];
                        addressConsulted.complement = this._personConsultedByCnpj.addressList[0].complement;
                        addressConsulted.neighborhood = this._personConsultedByCnpj.addressList[0].neighborhood;
                        addressConsulted.street = this._personConsultedByCnpj.addressList[0].street;
                        addressConsulted.streetNumber = this._personConsultedByCnpj.addressList[0].streetNumber;
                        addressConsulted.type = 'RESIDENTIAL';
                        addressConsulted.useInInvoice = this._personConsultedByCnpj.addressList[0].useInInvoice;
                        addressConsulted.zipCode = this._personConsultedByCnpj.addressList[0].zipCode;
                        this.person.addressList = [addressConsulted];
                    }
                }
                this.loading = false;
                this.findCnpjOrCpfChange.emit();
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.changeDetectorRef.detectChanges();
                return Promise.resolve(true);
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
                return Promise.reject(e);
            }
        });
    }
    refreshPersonByPersonConsultedCnpj() {
        try {
            if (this.disabled ||
                this.loading ||
                this.isViewing() ||
                this.isCreating() ||
                !this.hasPermissionForOperation() ||
                ((this.typeRegister !== 'CLIENT') && (this.typeRegister !== 'PROVIDER') && (this.typeRegister !== 'REPRESENTATIVE_PROVIDER'))) {
                return;
            }
            if (StringUtil.isEmpty(this.person.legalPerson.cnpj)) {
                this.addWarningMessage('Preencha o campo para realizar a consulta');
                return;
            }
            if (!DocumentUtil.isValidCnpj(this.person.legalPerson.cnpj)) {
                this.addWarningMessage('CNPJ informado é inválido');
                return;
            }
            this._setPersonConsultedByCnpjInPerson();
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    validateTypePerson(typePerson) {
        if (StringUtil.isEmpty(typePerson)) {
            return;
        }
        if (typePerson !== 'LEGAL_PERSON') {
            this.person.birthDate = null;
            this.person.legalPerson = null;
            if ((typePerson === 'FOREIGN_PERSON')) {
                this.person.typePerson = 'FOREIGN_PERSON';
                if (!ArrayUtil.isEmpty(this.person.addressList)) {
                    for (let i = 0; i < this.person.addressList.length; i++) {
                        this.person.addressList[i].country = null;
                        this.person.addressList[i].city = null;
                        this.person.addressList[i].state = null;
                    }
                }
            }
            else {
                this.person.typePerson = 'NATURAL_PERSON';
            }
            if (ObjectUtil.isNull(this.person.naturalPerson)) {
                this.person.naturalPerson = new RegistrationNaturalPerson();
            }
        }
        else {
            this.person.typePerson = 'LEGAL_PERSON';
            this.person.naturalPerson = null;
            this.person.birthDate = new Date();
            if (ObjectUtil.isNull(this.person.legalPerson)) {
                this.person.legalPerson = new RegistrationLegalPerson();
                // Mudou o lineOfBusiness para a entidade da empresa
                // if (this.typeRegister === 'COMPANY') {
                //     this.person.legalPerson.lineOfBusiness = new RegistrationLineOfBusiness();
                // }
            }
        }
    }
    removeSpecialCharactersStateRegistration() {
        if (this.person.typePerson === 'LEGAL_PERSON' && !StringUtil.isEmpty(this.person.legalPerson.stateRegistration)) {
            this.person.legalPerson.stateRegistration = StringUtil.removeAccents(this.person.legalPerson.stateRegistration);
        }
        else if (this.person.typePerson === 'NATURAL_PERSON' && !StringUtil.isEmpty(this.person.naturalPerson.documentNumber)) {
            this.person.naturalPerson.documentNumber = StringUtil.removeAccents(this.person.naturalPerson.documentNumber);
        }
    }
    setNameInFantasyName() {
        if (!StringUtil.isEmpty(this.person.name) && StringUtil.isEmpty(this.person.fantasyName)) {
            this.person.fantasyName = this.person.name;
        }
    }
    hasPermissionForOperation() {
        if (this.typeRegister === 'CLIENT') {
            return this.hasPermission('CLIENT_MAKE') && this.hasPermission('CLIENT_UPDATE');
        }
        else if ((this.typeRegister === 'PROVIDER') || (this.typeRegister === 'REPRESENTATIVE_PROVIDER')) {
            return this.hasPermission('PROVIDER_MAKE') && this.hasPermission('PROVIDER_UPDATE');
        }
        return false;
    }
    openClientSheetCnpjDialog() {
        if (!ObjectUtil.isNull(this.person) && !ObjectUtil.isNull(this.person.legalPerson) && !StringUtil.isEmpty(this.person.legalPerson.cnpj)) {
            this.baseService.dialogService.open({
                componentOrTemplateRef: GIPIClientSheetCnpjDialogComponent,
                data: this.person.legalPerson.cnpj,
                width: '55%',
                height: '90%',
            });
        }
    }
    viewConnectConsult() {
        try {
            if (!this._connectConfigEnabled$.getValue()) {
                this.addWarningMessage('Para prosseguir com a consulta da análise de crédito, configure a integração com a Connect SA');
                return;
            }
            const isNaturalPerson = (!ObjectUtil.isNull(this.person) && !ObjectUtil.isNull(this.person.naturalPerson));
            const cpfOrCnpj = (isNaturalPerson ? this.person.naturalPerson.cpf : this.person.legalPerson.cnpj);
            if (StringUtil.isEmpty(cpfOrCnpj)) {
                this.addWarningMessage(`${isNaturalPerson ? 'CPF' : 'CNPJ'} não informado. Para prosseguir com a consulta, por favor, preencha o campo`);
                return;
            }
            const connectConsultData = {
                consultBy: 'CPF_OR_CNPJ',
                clientId: null,
                cpfOrCnpj: cpfOrCnpj,
                consultType: 'BASIC',
                documentType: this.person.typePerson,
                showDunningNotice: true,
            };
            this.baseService.dialogService.open({
                componentOrTemplateRef: ConnectConsultDialogComponent,
                data: connectConsultData,
                width: '40%',
                height: '42rem',
            });
        }
        catch (e) {
            this.handleError(e);
        }
    }
}
