import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialCardAdministrator } from '@gipi-financial/card-administrator/models/card-administrador.model';
import { FinancialReceivementCard } from '@gipi-financial/card-administrator/models/receivement-card.model';
import { FinancialCardAdministratorService } from '@gipi-financial/card-administrator/services/card-administrator.service';
import { FinancialFlagCard } from '@gipi-financial/flag-card/models/flag-card.model';
import { FinancialFlagCardService } from '@gipi-financial/flag-card/services/flag-card.service';
import { FinancialReceivement } from '@gipi-financial/receivement/models/receivement.model';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { APP_MESSAGES, ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, NumberUtil, ObjectUtil, PageDTO, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface ReceivementCardData {
    receivement: FinancialReceivement;
    installmentList: FinancialBillInstallment[];
    isCashier: boolean;
    useClientCredit: boolean;
}

@Component({
    templateUrl: './receivement-card-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }`]
})
export class ReceivementCardDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    @ViewChild('actions', { static: true }) actions: TemplateRef<any>;

    private _receivement: FinancialReceivement = null;

    public receivementCardList: FinancialReceivementCard[] = [];

    public columns: TableColumnDTO[] = [];

    public receivementCard: FinancialReceivementCard = this._newReceivementCard();

    public quantityInstallment: number = 1;

    public optionAmountCard: any = { allowNegative: false, min: 0, max: this.amountRemaining };

    cardAdministratorFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialCardAdministrator> = await this._cardAdministratorService.findByValue(value, page, 10).toPromise();
        return result;
    };

    flagCardFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialFlagCard> = await this._flagCardService.findByValue(value, page, 10).toPromise();
        return result;
    };

    public get amountRemaining$(): Observable<number> {
        return of(this.amountRemaining);
    }

    public get isValidAddReceivementCard$(): Observable<boolean> {
        if (ObjectUtil.isNull(this.receivementCard)) {
            return of(false);
        }

        return of(
            !ObjectUtil.isNull(this.receivementCard.cardAdministrator) &&
            !ObjectUtil.isNull(this.receivementCard.flagCard) &&
            NumberUtil.isPositive(this.receivementCard.amountCard) &&
            (this.quantityInstallment >= 1)
        );
    }

    public get amountToReceive(): number {
        const receivementAux: FinancialReceivement = !ObjectUtil.isNull(this._receivement) ? this._receivement : this.data.receivement;
        return (receivementAux.amountReceived ? receivementAux.amountReceived : receivementAux.amountReceivable);
    }

    public get amountAdded(): number {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            return 0;
        }
        const amountAdded: number = this.receivementCardList.reduce((sum, e) => sum += e.amountCard, 0);
        return amountAdded;
    }

    public get amountRemaining(): number {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            return this.amountToReceive;
        }
        return (this.amountToReceive - this.amountAdded);
    }

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _cardAdministratorService: FinancialCardAdministratorService,
        private _flagCardService: FinancialFlagCardService,
        private _receivementService: FinancialReceivementService,
        public dialogRef: MatDialogRef<ReceivementCardDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReceivementCardData = null,
    ) {
        super(baseService, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.columns = this._createTableColumns();

        if (!ObjectUtil.isNull(this.data)) {
            this._receivement = ObjectUtil.clone(this.data.receivement);
            this._receivement.amountNet = this._receivement.amountReceivable;
        }

        this._changeDetectorRef.markForCheck();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private _createTableColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('numberInstallment')
                .name('N°')
                .value((obj: FinancialReceivementCard) => ('00' + obj.numberInstallment).slice(-2))
                .width(5)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('issuanceDate')
                .name('Emissão')
                .value((obj: FinancialReceivementCard) => DateUtil.format(obj.issuanceDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .value((obj: FinancialReceivementCard) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('cardAdministrator')
                .name('Administradora de cartão')
                .value((obj: FinancialReceivementCard) => obj.cardAdministrator.description)
                .marginLeft(15)
                .build(),
            TableColumnBuilder.instance()
                .property('flagCard')
                .name('Bandeira do cartão')
                .value((obj: FinancialReceivementCard) => obj.flagCard.description)
                .build(),
            TableColumnBuilder.instance()
                .property('amountCard')
                .name('Valor')
                .value((obj: FinancialReceivementCard) => CurrencyUtil.transform(obj.amountCard, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .template(this.actions)
                .align('center center')
                .width(10)
                .build(),
        ];
    }

    private _newReceivementCard(): FinancialReceivementCard {
        const entity: FinancialReceivementCard = new FinancialReceivementCard();
        entity.chargeType = ObjectUtil.clone(this.data.receivement.chargeType);
        entity.bankAccount = ObjectUtil.clone(this.data.receivement.bankAccount);
        entity.cardAdministrator = null;
        entity.flagCard = null;
        entity.issuanceDate = new Date();
        entity.dueDate = new Date();
        entity.status = 'RECEIVABLE';
        entity.amountCard = this.amountRemaining;
        return entity;
    }

    private _getOrderId(): number {
        const orderId: number[] = this.receivementCardList.map(e => e.orderId);
        if (orderId.length > 0) {
            return Math.max(...orderId) + 1;
        }

        return 1;
    }

    private _validateAddReceivementCard(): boolean {
        if (ObjectUtil.isNull(this.receivementCard.cardAdministrator)) {
            this.addWarningMessage('Campo administradora de cartão é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.receivementCard.flagCard)) {
            this.addWarningMessage('Campo bandeira do cartão é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.quantityInstallment)) {
            this.addWarningMessage('Campo quantidade de parcelas é obrigatório e não foi informado');
            return false;
        }

        const amountAddedAux: number = this.amountAdded;
        const amountToReceiveAux: number = this.amountToReceive;
        if (amountAddedAux === amountToReceiveAux) {
            this.addWarningMessage(`O valor lançado R$ ${(amountAddedAux).toFixed(2)} já corresponde ao valor total do recebimento`);
            return false;
        }
        if ((this.receivementCard.amountCard + amountAddedAux) > amountToReceiveAux) {
            this.addWarningMessage(`O valor que você está tentando lançar R$ ${(amountAddedAux).toFixed(2)} ultprassa o valor total do recebimento`);
            return
        }
        if (!NumberUtil.isPositive(this.receivementCard.amountCard)) {
            this.addWarningMessage('Campo valor é obrigatório e não foi informado');
            return false;
        }
        if (!DateUtil.isValid(this.receivementCard.issuanceDate)) {
            this.addWarningMessage('Campo data da emissão é obrigatório e não foi informado');
            return false;
        }
        if ((this.quantityInstallment > 1) && ((this.receivementCard.amountCard / this.quantityInstallment) < 1)) {
            this.addWarningMessage('Não é possível gerar parcelas com valor abaixo de R$ 1,00');
            return false;
        }

        return true;
    }

    public addReceivementCard(): void {
        try {
            if (!this._validateAddReceivementCard()) {
                return;
            }
            this.loading = true;

            const receivementCardListAux: FinancialReceivementCard[] = ArrayUtil.clone(this.receivementCardList);
            const now: Date = new Date();
            const orderId: number = this._getOrderId();
            const amountPortion: number = (this.receivementCard.amountCard / this.quantityInstallment);
            const daysAdd: number = (
                (this.receivementCard.chargeType.type === 'CREDIT_CARD') ?
                    this.receivementCard.cardAdministrator.dayCreditReceipt :
                    this.receivementCard.cardAdministrator.dayDebitReceipt
            );

            for (let i = 0; i < this.quantityInstallment; i++) {
                const receivementCard: FinancialReceivementCard = ObjectUtil.clone(this.receivementCard);
                receivementCard.amountCard = Number(amountPortion.toFixed(2));
                receivementCard.dueDate = new Date(now.getFullYear(), now.getMonth() + i, now.getDate() + daysAdd);
                receivementCard.numberInstallment = i + 1;
                receivementCard.orderId = orderId

                receivementCardListAux.push(receivementCard);
            }

            const amountAddedAux = receivementCardListAux.reduce((sum, e) => sum + e.amountCard, 0);
            const amountDifference: number = Number(amountAddedAux.toFixed(2)) - this.receivementCard.amountCard;
            if (amountDifference > 0) {
                receivementCardListAux[0].amountCard -= amountDifference;
            } else if (amountDifference < 0) {
                receivementCardListAux[0].amountCard += (amountDifference * -1);
            }

            this.receivementCardList = [...receivementCardListAux];
            this.receivementCard = this._newReceivementCard();
            this.quantityInstallment = 1;

            this.loading = false;
            this._changeDetectorRef.detectChanges();
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    public removeReceivementCard(entity: FinancialReceivementCard): void {
        try {
            this.loading = true;
            const receivementCardListAux: FinancialReceivementCard[] = ArrayUtil.clone(this.receivementCardList);
            const indexReceivementCard: number = receivementCardListAux.findIndex(e => (e.orderId === entity.orderId) && (e.numberInstallment === entity.numberInstallment));
            receivementCardListAux.splice(indexReceivementCard, 1);

            const now: Date = new Date();
            const receivementCardByOrderId: FinancialReceivementCard[] = receivementCardListAux.filter(e => e.orderId === entity.orderId);
            for (let i = 0; i < receivementCardByOrderId.length; i++) {
                const daysAdd: number = (
                    (receivementCardByOrderId[i].chargeType.type === 'CREDIT_CARD') ?
                        receivementCardByOrderId[i].cardAdministrator.dayCreditReceipt :
                        receivementCardByOrderId[i].cardAdministrator.dayDebitReceipt
                );

                receivementCardByOrderId[i].dueDate = new Date(now.getFullYear(), now.getMonth() + i, now.getDate() + daysAdd);
                receivementCardByOrderId[i].numberInstallment = i + 1;
            }

            this.receivementCardList = [...receivementCardListAux];
            this.receivementCard = this._newReceivementCard();
            this.quantityInstallment = 1;

            this.loading = false;
            this._changeDetectorRef.detectChanges();
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    private _validateConfirm(): boolean {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            this.addWarningMessage('É necessário adicionar no mínimo uma parcela, para salvar o recebimento');
            return false;
        }

        const amountAddedAux: number = Number(this.amountAdded.toFixed(2));
        const amountToReceiveAux: number = this.amountToReceive;
        if (amountAddedAux < amountToReceiveAux) {
            this.addWarningMessage(`Ainda falta lançar R$ ${CurrencyUtil.transform(this.amountRemaining, '1.2-2')} para corresponder o valor total do recebimento`);
            return false;
        }
        if (amountAddedAux > amountToReceiveAux) {
            this.addWarningMessage(`O valor lançado R$ ${CurrencyUtil.transform(Number((amountAddedAux).toFixed(2)), '1.2-2')} está ultrapassando o valor total do recebimento`);
            return false;
        }
        return true;
    }

    public confirm(): void {
        try {
            if (!this._validateConfirm()) {
                return;
            }
            this.loading = true;

            this._receivementService.receive(this._receivement, this.data.installmentList, this.receivementCardList, this.data.useClientCredit).toPromise().then(_ => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close(true);
            }).catch(error => {
                this.loading = false;
                this.addErrorMessage(error);
            });
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    public close(isSaved: boolean): void {
        this.dialogRef.close(isSaved);
    }

}
