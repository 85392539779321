import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialBank } from '@gipi-financial/bank/models/bank.model';
import { FinancialBankService } from '@gipi-financial/bank/services/bank.service';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialCheckReceived } from '@gipi-financial/check-received/models/check-received.model';
import { FinancialCheck } from '@gipi-financial/check/models/check.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { FinancialReceivement } from '@gipi-financial/receivement/models/receivement.model';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, ArrayUtil, CurrencyUtil, DateUtil, DocumentUtil, INJECTOR, NumberUtil, ObjectUtil, PageDTO, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface ReceivementCheckData {
    receivement: FinancialReceivement;
    installmentList: FinancialBillInstallment[];
    isCashier: boolean;
    useClientCredit: boolean;
}

@Component({
    templateUrl: './receivement-check-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class ReceivementCheckDialogComponent extends AbstractComponent implements OnInit {

    @ViewChild('actions', { static: true }) actions: TemplateRef<any>;

    receivement: FinancialReceivement;

    billInstallmentList: FinancialBillInstallment[] = [];

    checkReceivedList: FinancialCheckReceived[] = [];

    checkReceived: FinancialCheckReceived = new FinancialCheckReceived();

    receivementAmount: number = 0;

    amountAdded: number = 0;

    amountRemaining: number = 0;

    bankFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialBank> = await this._bankService.findByValue(value, page, 50).toPromise();
        return result;
    };

    bankFn = (obj: FinancialBank) => `${obj.code} - ${obj.name}`;

    postingCategoryFn = (obj: FinancialPostingCategory) => `${obj.fullCode} - ${obj.description}`;

    postingCategoryFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialPostingCategory> = await this._postingCategoryService.findByValue(value, page, 10, { property: 'fullCode', direction: 'asc' }, 'CREDIT').toPromise();
        if (!ObjectUtil.isNull(result) && !ArrayUtil.isEmpty(result.content)) {
            result.content = result.content.filter(category => category.enabled && !category.hasChildren);

            if (this.billInstallmentList.length === 1) {
                this.checkReceived.check.category = this.billInstallmentList[0].postingCategory;
            }
        }
        return result;
    };

    constructor(
        public dialogRef: MatDialogRef<ReceivementCheckDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReceivementCheckData = { receivement: null, installmentList: [], isCashier: false, useClientCredit: false },
        private _receivementService: FinancialReceivementService,
        private _bankService: FinancialBankService,
        private _postingCategoryService: FinancialPostingCategoryService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.receivement = this.data.receivement;
        this.receivement.amountNet = this.receivement.amountReceivable;
        this.receivementAmount = (this.receivement.amountReceived ? this.receivement.amountReceived : this.receivement.amountReceivable);
        this.receivement.checkReceivedList = [];
        this.billInstallmentList = this.data.installmentList;
        this.newCheckReceived();
    }


    addCheck(): void {
        try {
            this.validateAddNewCheckReceived();
            this.receivement.checkReceivedList = Array(...this.receivement.checkReceivedList, ObjectUtil.clone(this.checkReceived));
            this.newCheckReceived();
        } catch (e) {
            this.handleError(e);
        }
    }

    removeCheck(check: FinancialCheckReceived): void {
        const checkList: FinancialCheckReceived[] = [];
        checkList.push(...this.receivement.checkReceivedList);
        checkList.splice(checkList.indexOf(check), 1);
        this.receivement.checkReceivedList = checkList;
        this.calculateAmountReceivable();
    }

    newCheckReceived(): void {
        this.checkReceived = new FinancialCheckReceived();
        this.checkReceived.check = new FinancialCheck();
        this.checkReceived.check.status = 'OPENED';

        this.checkReceived.check.category = ((this.billInstallmentList) && (this.billInstallmentList.length <= 1)) ? this.billInstallmentList[0].postingCategory : null;

        this.checkReceived.client = this.billInstallmentList[0].bill.client;
        this.checkReceived.issuer = this.checkReceived.client.person.name;

        this.checkReceived.cpfOrCnpjIssuer = ' ';
        if (!ObjectUtil.isNewModel(this.checkReceived.client.person.legalPerson)) {
            this.checkReceived.cpfOrCnpjIssuer = this.checkReceived.client.person.legalPerson.cnpj ? this.checkReceived.client.person.legalPerson.cnpj : ' ';
        } else if (!ObjectUtil.isNewModel(this.checkReceived.client.person.naturalPerson)) {
            this.checkReceived.cpfOrCnpjIssuer = this.checkReceived.client.person.naturalPerson.cpf ? this.checkReceived.client.person.naturalPerson.cpf : ' ';
        }

        this.checkReceived.bank = null;
        this.checkReceived.bankAccount = this.data.receivement.bankAccount;
        this.checkReceived.check.issuanceDate = new Date();
        this.checkReceived.check.dueDate = new Date();
        this.checkReceived.comments = ' ';
        this.calculateAmountReceivable();
    }

    calculateAmountReceivable(): void {
        this.amountAdded = this.receivement.checkReceivedList.reduce((sum, checkReceived) => sum += checkReceived.check.amount, 0);
        this.amountRemaining = this.receivementAmount - this.amountAdded;
        this.checkReceived.check.amount = this.amountRemaining;
    }

    async confirm(): Promise<void> {
        try {
            this.validateConfirm();
            this.loading = true;
            await this._receivementService.receive(this.receivement, this.billInstallmentList, [], this.data.useClientCredit).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close(true);
            }, error => {
                this.loading = false;
                this.handleError(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    validateConfirm(): void {
        const amountAdded: number = Number(this.receivement.checkReceivedList.reduce((sum, checkReceived) => sum += checkReceived.check.amount, 0).toFixed(2));
        const amountReceivable: number = Number((this.receivementAmount - amountAdded).toFixed(2));

        if (amountAdded < this.receivementAmount) {
            throw new Error(`Ainda falta lançar R$ ${CurrencyUtil.transform(amountReceivable, '1.2-2')} para corresponder o valor total do recebimento`);
        }
        if (amountAdded > this.receivementAmount) {
            throw new Error(`O valor lançado R$ ${CurrencyUtil.transform(Number((amountAdded).toFixed(2)), '1.2-2')} está ultrapassando o valor total do recebimento`);
        }
        if (ArrayUtil.isEmpty(this.receivement.checkReceivedList)) {
            throw new Error('É necessário adicionar no mínimo um cheque, para salvar o recebimento');
        }
    }

    validateAddNewCheckReceived(): void {
        if (StringUtil.isEmpty(this.checkReceived.cpfOrCnpjIssuer.trim())) {
            throw new Error('Campo CPF/CNPJ é obrigatório e não foi informado');
        } else if ((this.checkReceived.cpfOrCnpjIssuer.length !== 11) && (this.checkReceived.cpfOrCnpjIssuer.length !== 14)) {
            throw new Error('Campo CPF/CNPJ foi informado incorretamente');
        } else {
            if ((this.checkReceived.cpfOrCnpjIssuer.length === 11) && !DocumentUtil.isValidCpf(this.checkReceived.cpfOrCnpjIssuer)) {
                throw new Error('Campo CPF/CNPJ foi informado incorretamente');
            }
            if ((this.checkReceived.cpfOrCnpjIssuer.length === 14) && !DocumentUtil.isValidCnpj(this.checkReceived.cpfOrCnpjIssuer)) {
                throw new Error('Campo CPF/CNPJ foi informado incorretamente');
            }
        }
        if (StringUtil.isEmpty(this.checkReceived.issuer)) {
            throw new Error('Campo emitente é obrigatório e não foi informado');
        }
        if (!StringUtil.isEmpty(this.checkReceived.phone) && this.checkReceived.phone.length !== 10) {
            if (!StringUtil.isEmpty(this.checkReceived.phone) && this.checkReceived.phone.length !== 11) {
                throw new Error('Campo telefone foi informado incorretamente');
            }
        }
        if (!NumberUtil.isPositive(this.checkReceived.check.checkNumber)) {
            throw new Error('Campo n° cheque é obrigatório e não foi informado');
        }
        const amountAdded: number = this.receivement.checkReceivedList.reduce((sum, checkReceived) => sum += checkReceived.check.amount, 0);
        if (amountAdded === this.receivementAmount) {
            throw new Error(`O valor lançado R$ ${(amountAdded).toFixed(2)} já corresponde ao valor total do recebimento`);
        }
        if ((this.checkReceived.check.amount + amountAdded) > this.receivementAmount) {
            throw new Error(`O valor que você está tentando lançar R$ ${(amountAdded).toFixed(2)} ultprassa o valor total do recebimento`);
        }
        if (!NumberUtil.isPositive(this.checkReceived.check.amount)) {
            throw new Error('Campo valor é obrigatório e não foi informado');
        }
        if (!DateUtil.isValid(this.checkReceived.check.issuanceDate)) {
            throw new Error('Campo data da emissão é obrigatório e não foi informado');
        }
        if (!DateUtil.isValid(this.checkReceived.check.dueDate)) {
            throw new Error('Campo data do vencimento é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNewModel(this.checkReceived.check.category) || ObjectUtil.isNull(this.checkReceived.check.category)) {
            throw new Error('Campo categoria é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNewModel(this.checkReceived.bankAccount) || ObjectUtil.isNull(this.checkReceived.bankAccount)) {
            throw new Error('Campo conta bancária para depósito é obrigatório e não foi informado');
        }
    }

    createTableColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .value((obj: FinancialCheckReceived) => DateUtil.format(obj.check.dueDate, DateUtil.DATE_FORMAT))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('checkNumber')
                .name('N° cheque')
                .value((obj: FinancialCheckReceived) => obj.check.checkNumber)
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('postingCategory.description')
                .name('Categoria')
                .value((obj: FinancialCheckReceived) => obj.check.category.description)
                .width(15)
                .build(),
            TableColumnBuilder.instance()
                .property('eminent')
                .name('Emitente')
                .value((obj: FinancialCheckReceived) => obj.issuer)
                .build(),
            TableColumnBuilder.instance()
                .property('bankAccount.description')
                .name('Conta bancária')
                .value((obj: FinancialCheckReceived) => obj.bankAccount.description)
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .value((obj: FinancialCheckReceived) => CurrencyUtil.transform(obj.check.amount, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .template(this.actions)
                .align('center center')
                .width(7)
                .build(),
        ];
    }

    close(isSaved: boolean): void {
        this.dialogRef.close(isSaved);
    }

}
