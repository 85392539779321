import { ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import * as moment_ from 'moment';
const moment = moment_;

import { FinancialReceivementCardOriginEnum } from '@gipi-financial/card-administrator/enums/receivement-card-origin.enum';
import { FinancialReceivementCardType, FinancialReceivementCardTypeEnum } from '@gipi-financial/card-administrator/enums/receivement-card-type.enum';
import { FinancialCardAdministrator } from '@gipi-financial/card-administrator/models/card-administrador.model';
import { FinancialReceivementCardFilterDTO } from '@gipi-financial/card-administrator/models/dto/receivement-card-filter.dto';
import { FinancialReceivementCard } from '@gipi-financial/card-administrator/models/receivement-card.model';
import { FinancialCardAdministratorService } from '@gipi-financial/card-administrator/services/card-administrator.service';
import { FinancialReceivementCardService } from '@gipi-financial/card-administrator/services/receivement-card.service';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { FinancialChargeTypeService } from '@gipi-financial/charge-type/services/charge-type.service';
import { FinancialFlagCard } from '@gipi-financial/flag-card/models/flag-card.model';
import { FinancialFlagCardService } from '@gipi-financial/flag-card/services/flag-card.service';
import { ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIAppliedFilter, GIPIBaseService, GIPIPageModel, GIPIUuid, ObjectUtil, SortDirectionEnum, SortDTO, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface FindReceivementCardData {
    receivementCardListSelected: FinancialReceivementCard[];
    bankAccountId: GIPIUuid;
}

export type FilterTypes = 'begin_and_end_date' | 'begin_date' | 'end_date' | 'document_number_origin' | 'amount' | 'card_administrator' | 'flag_card' | 'charge_type';

@Component({
    templateUrl: './find-receivement-card-administrator.component.html',
    styleUrls: ['./find-receivement-card-administrator.component.scss']
})
export class FindReceivementCardComponent extends GIPIAbstractComponent implements OnInit {

    @ViewChild('checkboxTemplate', { static: true }) checkboxTemplate: TemplateRef<any>;
    @ViewChild('checkAllTemplate', { static: true }) checkAllTemplate: TemplateRef<any>;
    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

    public receivementCardListSelected: FinancialReceivementCard[] = [];
    public receivementCardTypeSelected: FinancialReceivementCardType = null;

    public filter: FinancialReceivementCardFilterDTO = this._newFilter();
    public page: GIPIPageModel<FinancialReceivementCard> = new GIPIPageModel();
    public columns: TableColumnDTO[] = [];

    public receivementCardTypeEnum: typeof FinancialReceivementCardTypeEnum = FinancialReceivementCardTypeEnum;

    public appliedFilters: GIPIAppliedFilter<FilterTypes>[] = [];

    public selected: boolean = false;
    public indeterminate: boolean = false;

    public allStatusChecked: boolean = true;
    public indeterminateStatusChecked: boolean = false;
    public receivableStatusChecked: boolean = false;
    public receivedStatusChecked: boolean = false;
    public overdueStatusChecked: boolean = false;
    public creditCardStatusChecked: boolean = false;
    public debitCardStatusChecked: boolean = false;

    cardAdministratorFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialCardAdministrator> = await this._cardAdministratorService.findByValue(value, page, 50).toPromise();
        return result;
    };

    flagCardFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialFlagCard> = await this._flagCardService.findByValue(value, page, 50).toPromise();
        return result;
    };

    chargeTypeFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialChargeType> = await this._chargeTypeService.findByValue(value, page, 50).toPromise();
        return result;
    };

    constructor(
        protected service: FinancialReceivementCardService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _flagCardService: FinancialFlagCardService,
        private _chargeTypeService: FinancialChargeTypeService,
        private _cardAdministratorService: FinancialCardAdministratorService,
        public dialogRef: MatDialogRef<FindReceivementCardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FindReceivementCardData = { receivementCardListSelected: [], bankAccountId: null, },
    ) {
        super(baseService, activatedRoute);
    }

    ngOnInit(): void {
        this.receivementCardListSelected = [...this.data.receivementCardListSelected];

        if (!ArrayUtil.isEmpty(this.receivementCardListSelected)) {
            this.receivementCardTypeSelected = this.receivementCardListSelected[0].status;
        }

        this.columns = this._createTableColumns();
        super.ngOnInit();

        this._changeDetectorRef.markForCheck();
    }

    private _newFilter(): FinancialReceivementCardFilterDTO {
        const filter: FinancialReceivementCardFilterDTO = new FinancialReceivementCardFilterDTO();
        filter.researchField = '';
        filter.cardAdministrator = null;
        filter.cardAdministratorId = null;
        filter.chargeType = null;
        filter.chargeTypeId = null;
        filter.flagCard = null;
        filter.flagCardId = null;
        filter.begin = null;
        filter.end = null;
        filter.typeList = [];
        filter.amount = null;
        filter.receivementStatusList = ['RECEIVED', 'RECEIVABLE', 'OVERDUE'];
        filter.documentNumberOrigin = '';
        filter.concilied = false;
        return filter;
    }

    private _createTableColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkAllTemplate)
                .template(this.checkboxTemplate)
                .align('center center')
                .width(5)
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .sortable(true)
                .value((obj: FinancialReceivementCard) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .align('center center')
                .width(10)
                .marginRight(15)
                .action((obj: FinancialReceivementCard) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('cardAdministrator.description')
                .name('Adm. cartão')
                .value((obj: FinancialReceivementCard) => obj.cardAdministrator.description)
                .sortable(true)
                .width(15)
                .marginRight(15)
                .action((obj: FinancialReceivementCard) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('flagCard.description')
                .name('Bandeira')
                .sortable(true)
                .value((obj: FinancialReceivementCard) => obj.flagCard.description)
                .action((obj: FinancialReceivementCard) => this._handleActionTable(obj))
                .width(12)
                .marginRight(15)
                .build(),
            TableColumnBuilder.instance()
                .property('chargeType.description')
                .name('Tipo de cobrança')
                .sortable(true)
                .value((obj: FinancialReceivementCard) => obj.chargeType.description)
                .action((obj: FinancialReceivementCard) => this._handleActionTable(obj))
                .marginRight(15)
                .build(),
            TableColumnBuilder.instance()
                .property('amountNet')
                .name('Valor')
                .value((obj: FinancialReceivementCard) => CurrencyUtil.transform(obj.amountNet, '1.2-2'))
                .align('center center')
                .width(10)
                .action((obj: FinancialReceivementCard) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('documentOrigin')
                .name('Doc. Origem')
                .value((obj: FinancialReceivementCard) => !StringUtil.isEmpty(obj.documentOrigin) ? obj.documentOrigin : '')
                .align('center center')
                .width(10)
                .action((obj: FinancialReceivementCard) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('origin')
                .name('Origem')
                .value((obj: FinancialReceivementCard) => !StringUtil.isEmpty(obj.origin) ? FinancialReceivementCardOriginEnum[obj.origin] : '')
                .align('center center')
                .width(10)
                .action((obj: FinancialReceivementCard) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(7)
                .template(this.statusTemplate)
                .action((obj: FinancialReceivementCard) => this._handleActionTable(obj))
                .build(),
        ];
    }

    private _handleActionTable(entity: FinancialReceivementCard): void {
        if (ObjectUtil.isNull(entity) || !this.checkboxIsValid(entity)) {
            return;
        }

        entity.selected = !entity.selected;
        this.select(entity, true);

        this._changeDetectorRef.detectChanges();
    }

    public checkboxIsValid(entity: FinancialReceivementCard): boolean {
        if (ObjectUtil.isNull(this.receivementCardTypeSelected)) {
            return true;
        }

        return (!ObjectUtil.isNull(entity) && (entity.status === this.receivementCardTypeSelected));
    }

    public setAppliedFilters(): void {
        if (ObjectUtil.isNull(this.filter)) {
            return;
        }
        this.appliedFilters = [];

        if (!ObjectUtil.isNull(this.filter.begin) && !ObjectUtil.isNull(this.filter.end)) {
            const description: string = `${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)} á ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>(description, 'begin_and_end_date'));
        } else {
            if (!ObjectUtil.isNull(this.filter.begin)) {
                const description: string = `Data inicial: ${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>(description, 'begin_date'));
            }
            if (!ObjectUtil.isNull(this.filter.end)) {
                const description: string = `Data final: ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>(description, 'end_date'));
            }
        }
        if (!StringUtil.isEmpty(this.filter.documentNumberOrigin)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Documento de origem', 'document_number_origin'));
        }
        if (this.filter.amount) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Valor', 'amount'));
        }
        if (!ObjectUtil.isNull(this.filter.cardAdministrator)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Administradora do cartão', 'card_administrator'));
        }
        if (!ObjectUtil.isNull(this.filter.flagCard)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Bandeira do cartão', 'flag_card'));
        }
        if (!ObjectUtil.isNull(this.filter.chargeType)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Tipo de cobrança', 'charge_type'));
        }
    }

    public removeAppliedFilter(filter: { chip: GIPIAppliedFilter<FilterTypes>, index: number }): void {
        switch (filter.chip.key) {
            case 'begin_and_end_date': {
                this.filter.begin = null;
                this.filter.end = null;
                break;
            }
            case 'begin_date': {
                this.filter.begin = null;
                break;
            }
            case 'end_date': {
                this.filter.end = null;
                break;
            }
            case 'document_number_origin': {
                this.filter.documentNumberOrigin = '';
                break;
            }
            case 'amount': {
                this.filter.amount = null;
                break;
            }
            case 'card_administrator': {
                this.filter.cardAdministrator = null;
                this.filter.cardAdministratorId = null;
                break;
            }
            case 'flag_card': {
                this.filter.flagCard = null;
                this.filter.flagCardId = null;
                break;
            }
            case 'charge_type': {
                this.filter.chargeType = null;
                this.filter.chargeTypeId = null;
                break;
            }
        }

        this.find(null);
    }

    public find(pageEvent?: any): void {
        try {
            if (this.filter.begin && !DateUtil.isValid(new Date(this.filter.begin))) {
                this.loading = false;
                this.addWarningMessage('Data inicial não é uma data válida');
                return;
            }
            if (this.filter.end && !DateUtil.isValid(new Date(this.filter.end))) {
                this.loading = false;
                this.addWarningMessage('Data final não é uma data válida');
                return;
            }
            if (this.filter.begin && !this.filter.end) {
                this.loading = false;
                this.addWarningMessage('Data final não informada');
                return;
            }
            if (!this.filter.begin && this.filter.end) {
                this.loading = false;
                this.addWarningMessage('Data inicial não informada');
                return;
            }
            if (DateUtil.isLessThan(new Date(this.filter.end), new Date(this.filter.begin))) {
                this.loading = false;
                this.addWarningMessage('Data final não pode ser menor que data inicial');
                return;
            }

            this.loading = true;

            if (ObjectUtil.isNull(this.page)) {
                this.page = new GIPIPageModel();
            }
            if (ObjectUtil.isNull(this.filter)) {
                this.filter = this._newFilter();
            }

            this.page.content = [];

            this._setStatusEnumList();
            if (this.allStatusChecked) {
                this.filter.receivementStatusList = [];
            }

            if (!ObjectUtil.isNull(this.filter.cardAdministrator)) {
                this.filter.cardAdministratorId = Number(this.filter.cardAdministrator.id);
            }
            if (!ObjectUtil.isNull(this.filter.flagCard)) {
                this.filter.flagCardId = Number(this.filter.flagCard.id);
            }
            if (!ObjectUtil.isNull(this.filter.chargeType)) {
                this.filter.chargeTypeId = Number(this.filter.chargeType.id);
            }

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;

                if (pageEvent.sort) {
                    this.filter.sorts = [
                        new SortDTO(
                            pageEvent.sort.active,
                            StringUtil.isEmpty(pageEvent.sort.direction) ? SortDirectionEnum.DESC : pageEvent.sort.direction.toUpperCase()
                        )
                    ];
                }
            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
            }

            this.setAppliedFilters();

            this.service.findAll<FinancialReceivementCardFilterDTO, FinancialReceivementCard>(this.filter).subscribe(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    page.content.map(receivementCard => receivementCard.selected = (this.receivementCardListSelected.findIndex(s => s.id === receivementCard.id) >= 0));
                }

                this.page = page;
                this._validateAllSelected();
                this.loading = false;
            }, error => {
                this.loading = false;
                this.addWarningMessage(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public clear(): void {
        this.filter = this._newFilter();
        this.appliedFilters = [];
        this.find();
    }

    public close(isConfirm: boolean): void {
        try {
            if (isConfirm) {
                this.dialogRef.close(this.receivementCardListSelected);
            } else {
                this.dialogRef.close(null);
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    private _setStatusEnumList(): void {
        this.filter.receivementStatusList = [];
        this.filter.typeList = [];
        if (this.receivableStatusChecked) {
            this.filter.receivementStatusList.push('RECEIVABLE');
        }
        if (this.receivedStatusChecked) {
            this.filter.receivementStatusList.push('RECEIVED');
        }
        if (this.overdueStatusChecked) {
            this.filter.receivementStatusList.push('OVERDUE');
        }
        if (this.creditCardStatusChecked) {
            this.filter.typeList.push('CREDIT_CARD');
        }
        if (this.debitCardStatusChecked) {
            this.filter.typeList.push('DEBIT_CARD');
        }
    }

    public checkAllStatus(): void {
        this.receivableStatusChecked = false;
        this.receivedStatusChecked = false;
        this.overdueStatusChecked = false;
        this.creditCardStatusChecked = false;
        this.debitCardStatusChecked = false;

        this.indeterminateStatusChecked = false;
    }

    public validateAllStatusSelected(): void {
        if (
            this.receivableStatusChecked &&
            this.receivedStatusChecked &&
            this.overdueStatusChecked &&
            this.creditCardStatusChecked &&
            this.debitCardStatusChecked
        ) {
            this.allStatusChecked = true;
            this.indeterminateStatusChecked = false;
        } else if (
            this.receivableStatusChecked ||
            this.receivedStatusChecked ||
            this.overdueStatusChecked ||
            this.creditCardStatusChecked ||
            this.debitCardStatusChecked
        ) {
            this.indeterminateStatusChecked = true;
            this.allStatusChecked = false;
        } else {
            this.indeterminateStatusChecked = false;
            this.allStatusChecked = false;
        }
    }

    public select(receivementCard: FinancialReceivementCard, validate: boolean): void {
        try {
            const receivementCardTypeSelected: FinancialReceivementCardType = receivementCard.status;

            if ((this.receivementCardTypeSelected !== null) && (this.receivementCardTypeSelected !== receivementCardTypeSelected)) {
                setTimeout(() => receivementCard.selected = false);
                this.addWarningMessage('Não é possível selecionar recebimentos de status distintos');
                return;
            }

            if (receivementCard.selected) {
                this.receivementCardTypeSelected = receivementCardTypeSelected;
                this.receivementCardListSelected.push(receivementCard);
            } else {
                this.receivementCardListSelected.splice(this.receivementCardListSelected.findIndex(e => e.id === receivementCard.id), 1);
                if (this.receivementCardListSelected.length <= 0) {
                    this.receivementCardTypeSelected = null;
                }
            }

            if (validate) {
                this._validateAllSelected();
            }

            this._changeDetectorRef.detectChanges();
        } catch (e) {
            this.handleError(e);
        }
    }

    private _validateAllSelected(): void {
        const list: FinancialReceivementCard[] = this.page.content.filter(receivementCard => receivementCard.selected);
        this.selected = list.length > 0;
        this.indeterminate = list.length > 0 && list.length !== this.page.content.length;
    }

    public setColorStatus(entity: FinancialReceivementCard): string {
        switch (String(entity.status)) {
            case 'RECEIVABLE':
                return '#b4b4b4';
            case 'RECEIVED':
                return '#56b910';
            case 'OVERDUE':
                return '#f5db00';
            default:
                return '#b4b4b4';
        }
    }

}
