import { AfterViewInit, Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialCompany } from '@gipi-financial/company/models/company.model';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { FinancialProvider } from '@gipi-financial/provider/models/provider.model';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, CurrencyUtil, GIPIUuid, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    selector: 'itss-payment-voucher',
    templateUrl: './payment-voucher.component.html',
    styleUrls: ['./payment-voucher.component.scss']
})
export class PaymentVoucherComponent extends AbstractComponent implements OnInit, AfterViewInit {

    public company: FinancialCompany;

    public provider: FinancialProvider;

    public description: string = '';

    public comments: string = '';

    public amountPaid: number;

    public paymentDate: Date;

    public documentNumber: string = '';

    public isTwoWays: boolean = false;

    public isCreated: EventEmitter<void> = new EventEmitter();

    constructor(
        private _companyService: FinancialCompanyService,
        private _customAuthenticationService: CustomAuthenticationService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        await this.findCompanyData();
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.isCreated.emit());
    }

    async findCompanyData(): Promise<void> {
        try {
            const companyId: GIPIUuid = this._customAuthenticationService.tokenValue.user.currentCompany;
            await this._companyService.findByIdIn([Number(companyId)]).toPromise().then(companyList => {
                if (!ArrayUtil.isEmpty(companyList)) {
                    this.company = companyList[0];
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.handleError(e);
        }
    }

    getCpfOrCnpjCompany(): string {
        if ((ObjectUtil.isNull(this.company))
            || (!ObjectUtil.isNull(this.company) && ObjectUtil.isNull(this.company.person))) {
            return '';
        }

        if (!ObjectUtil.isNull(this.company.person.legalPerson)) {
            return StringUtil.format(this.company.person.legalPerson.cnpj, '00.000.000/0000-00')
        } else if (!ObjectUtil.isNull(this.company.person.naturalPerson)) {
            return StringUtil.format(this.company.person.naturalPerson.cpf, '000.000.000-00');
        }
        return '';
    }

    getCpfOrCnpjProvider(): string {
        if ((ObjectUtil.isNull(this.provider))
            || (!ObjectUtil.isNull(this.provider) && ObjectUtil.isNull(this.provider.person))) {
            return '';
        }

        if (!ObjectUtil.isNull(this.provider.person.legalPerson)) {
            return StringUtil.format(this.provider.person.legalPerson.cnpj, '00.000.000/0000-00')
        } else if (!ObjectUtil.isNull(this.provider.person.naturalPerson)) {
            return StringUtil.format(this.provider.person.naturalPerson.cpf, '000.000.000-00');
        }
        return '';
    }

    getAmountPaidInFull(): string {
        const amountPaidFormatted: string = CurrencyUtil.transform(this.amountPaid, '1.2-2');
        const amountPaidNotCurrency: string = amountPaidFormatted.substring(2).trim();
        return `${amountPaidFormatted} (${amountPaidNotCurrency.numberFullName(true)})`;
    }

    getDescription(): string {
        if (!StringUtil.isEmpty(this.comments)) {
            return this.comments;
        } else if (!StringUtil.isEmpty(this.description)) {
            return this.description;
        }

        return '';
    }

    formatDateInFull(): string {
        const lDate: Date = this.getDateWithoutTime(this.paymentDate);
        const day: string = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'][lDate.getDay()];
        const date: number = lDate.getDate();
        const month: string = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][lDate.getMonth()];
        const year: number = lDate.getFullYear();
        return (`${day}, ${date} de ${month} de ${year}`);
    }

    private getDateWithoutTime(pDate: Date): Date {
        const day: string = pDate.toString().substring(8, 10);
        const month: string = pDate.toString().substring(5, 7);
        const year: string = pDate.toString().substring(0, 4);
        const lDate: string = `${year}/${month}/${day}`;
        return new Date(lDate);
    }

}
