import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { RegistrationBankAccount } from "@gipi-registration/bank-account/models/bank-account.model";
import { RegistrationBankAccountService } from "@gipi-registration/bank-account/services/bank-account.service";

import { RegistrationCardAdministrator } from "@gipi-registration/card-administrator/models/card-administrador.model";
import { RegistrationCardAdministratorService } from "@gipi-registration/card-administrator/services/card-administrator.service";
import { OptionActiveSituation, OptionActiveSituationEnum } from "@gipi-shared/enums/option-active-situation.enum";
import { APP_MESSAGES, DocumentUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, ObjectUtil, PageDTO, StringUtil } from "@gipisistemas/ng-core";

export interface CardAdministratorFormData {
    typeOperation: 'NEW' | 'EDIT' | 'VIEW';
    cardAdministrator: RegistrationCardAdministrator;
}

@Component({
    templateUrl: './card-administrator-form-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class CardAdministratorFormDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    public typeOperation: 'NEW' | 'EDIT' | 'VIEW' = 'NEW';

    public cardAdministrator: RegistrationCardAdministrator = new RegistrationCardAdministrator();

    public optionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public optionActiveSituationValue: OptionActiveSituation = 'ENABLED';

    bankAccountFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<RegistrationBankAccount> = await this._bankAccountService.findByValue(value, page, 10).toPromise();
        return result;
    };

    constructor(
        protected service: RegistrationCardAdministratorService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<CardAdministratorFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CardAdministratorFormData = { typeOperation: 'NEW', cardAdministrator: null },
        private _bankAccountService: RegistrationBankAccountService,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.cardAdministrator = this.data.cardAdministrator;

            if (!this.cardAdministrator.enabled) {
                this.optionActiveSituationValue = 'DISABLED';
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.cardAdministrator)) {
                return;
            }
            if (StringUtil.isEmpty(this.cardAdministrator.cnpj)) {
                this.addWarningMessage('Campo CNPJ é obrigatório e não foi informado');
                return;
            }
            if (!DocumentUtil.isValidCnpj(this.cardAdministrator.cnpj)) {
                this.addWarningMessage('CNPJ informado é inválido');
                return;
            }
            if (StringUtil.isEmpty(this.cardAdministrator.description)) {
                this.addWarningMessage('Campo descrição é obrigatório e não foi informado');
                return;
            }
            if (this.cardAdministrator.description.length < 3) {
                this.addWarningMessage('Campo descrição deve conter no mínimo 3 caracteres');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.bankAccount)) {
                this.addWarningMessage('Campo conta bancária é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.cashFee)) {
                this.addWarningMessage('Campo taxa à vista é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.installmentFee)) {
                this.addWarningMessage('Campo taxa parcelado é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.debitFee)) {
                this.addWarningMessage('Campo taxa do débito é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.creditFee)) {
                this.addWarningMessage('Campo taxa do crédito é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.debitAnticipationFee)) {
                this.addWarningMessage('Campo taxa de antecipação do débito é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.creditAnticipationFee)) {
                this.addWarningMessage('Campo taxa de antecipação do crédito é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.dayCashReceipt)) {
                this.addWarningMessage('Campo dias para recebimento dinheiro é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.dayDebitReceipt)) {
                this.addWarningMessage('Campo dias para recebimento débito é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.dayCreditReceipt)) {
                this.addWarningMessage('Campo dias para recebimento crédito é obrigatório e não foi informado');
                return;
            }
            this.loading = true;

            this.cardAdministrator.enabled = (this.optionActiveSituationValue === 'ENABLED');

            this.service.save(this.cardAdministrator).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
