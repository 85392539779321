<div *ngIf="(isAuthenticated | async); else loginTemplate">

    <gipi-top-nav id="topNav">
        <div id="sectionImages">
            <img alt="Logo da GIPI Sistemas"
                 id="bigLogo"
                 src="../assets/logo/logo.svg"
                 width="28"
                 height="40" />
        </div>
        <div id="sectionContent">
            <div id="sectionCompanyList">
                <itss-select-entity-paged id="company"
                                          name="company"
                                          class="select-company"
                                          classSelectPanel="select-company-panel"
                                          svgIconPrefix="company"
                                          [(model)]="company"
                                          [options]="companyList"
                                          [propertyFn]="compnayFn"
                                          [showClear]="false"
                                          [hideFieldSearch]="true"
                                          [infiniteScroll]="false"
                                          (selectionChange)="changeCompany($event); clearSessionStorage()">
                </itss-select-entity-paged>
            </div>

            <div id="sectionActions">
                <gipi-novelties tooltip="Novidades"
                                svgIcon="novelties"
                                [hasNovelties]="hasNovelties"
                                (click)="openNoveltiesDialog()">
                </gipi-novelties>

                <gipi-notification tooltip="Notificações"
                                   svgIcon="notification"
                                   (click)="getNotifications()"
                                   [notification]="(isCompanyGIPI | async) && (hasNotification | async)">
                    <div class="panel-notifications"
                         *ngIf="(isCompanyGIPI | async)">
                        <div class="header">
                            <strong>Notificações</strong>
                        </div>
                        <div class="divisory"> </div>
                        <div *ngIf="(hasNotification | async)">
                            Há integrações de clientes prontas para faturamento.
                        </div>
                        <gipi-empty-state *ngIf="!(hasNotification | async)"> </gipi-empty-state>
                    </div>

                    <strong *ngIf="!(isCompanyGIPI | async)"> Em breve </strong>
                </gipi-notification>

                <gipi-user-profile id="userProfile"
                                   name="userProfile"
                                   arialLabel="Perfil de usuário"
                                   [imgSrc]="userPhotoSrc ? userPhotoSrc : '../assets/logo/user_default.svg'"
                                   [text]="'Olá, ' + (firstName | async)"
                                   [username]="(username | async)"
                                   [email]="(email | async)"
                                   [lastAccess]="(lastAccess | async)"
                                   svgIconEdit="person"
                                   svgIconExit="logout"
                                   (onClickEdit)="openUserInformationDialog()"
                                   (onClickExit)="logout()">
                </gipi-user-profile>
            </div>
        </div>
    </gipi-top-nav>

    <gipi-sidenav-container>
        <gipi-sidenav id="sidenav"
                      labelIntegration="Integrações"
                      labelConfiguration="Configurações"
                      svgIconIntegration="integration"
                      svgIconConfiguration="configuration"
                      [showTagNewIntegration]="true"
                      [showConfiguration]="hasPermission('GENERAL_SETTINGS')"
                      [showIntegration]="hasPermission('INTEGRATION_SETTINGS')"
                      [menuItems]="(menuList | async)"
                      (onClickMenuIntegration)="onMenuItemIntegrationChange()"
                      (onClickMenuConfiguration)="onMenuItemConfigurationChange()">
        </gipi-sidenav>
        <router-outlet> </router-outlet>
    </gipi-sidenav-container>

    <gipi-footer></gipi-footer>
</div>

<ng-template #loginTemplate>
    <router-outlet> </router-outlet>
</ng-template>