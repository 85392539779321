<gipi-abstract-form id="dialogRegisterClient"
                    name="dialogRegisterClient"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterClient">
        Cadastrar cliente
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">

        <!-- Tipo de pessoa, CPF ou CNPJ e Inscrição estadual ou Documento -->
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <gipi-input-select-enum label="Tipo de pessoa"
                                    id="clientTypePerson"
                                    name="clientTypePerson"
                                    [required]="true"
                                    [enum]="typePersonEnum"
                                    [disabled]="loading || (typeOperation === 'VIEW')"
                                    [(ngModel)]="client.person.typePerson"
                                    (ngModelChange)="validateTypePerson($event)"
                                    fxFlex
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
            <itss-input label="CPF"
                        id="clientCpfNaturalPerson"
                        name="clientCpfNaturalPerson"
                        mask="000.000.000-00"
                        *ngIf="client.person.typePerson === 'NATURAL_PERSON'"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [required]="true"
                        [(ngModel)]="client.person.naturalPerson.cpf"
                        (focusout)="consultCpfOrCnpj(true)"
                        (keydown.enter)="(typeOperation === 'NEW') ? consultCpfOrCnpj() : null"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="CNPJ"
                        #inputCnpj
                        id="clientCnpjLegalPerson"
                        name="clientCnpjLegalPerson"
                        *ngIf="client.person.typePerson === 'LEGAL_PERSON'"
                        mask="00.000.000/0000-00"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [required]="true"
                        [(ngModel)]="client.person.legalPerson.cnpj"
                        (keydown.enter)="(typeOperation === 'NEW') ? consultCpfOrCnpj() : null"
                        (blur)="(typeOperation === 'NEW') ? consultCpfOrCnpj(true) : null"
                        fxFlex
                        fxFlex.lt-md="100">
                <ng-template #suffix>
                    <div fxLayout="row"
                         fxLayoutGap="5px">
                        <gipi-button tooltip="Buscar CNPJ"
                                     gipi-icon
                                     id="btnSearchCnpjLegalPerson"
                                     svgIcon="search"
                                     [forInput]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW') || (typeOperation === 'EDIT') || !hasPermissionForOperation()"
                                     (click)="consultCpfOrCnpj()">
                        </gipi-button>
                    </div>
                </ng-template>
            </itss-input>
            <itss-input label="Documento"
                        id="clientDocumentNumberNaturalPerson"
                        name="clientDocumentNumberNaturalPerson"
                        *ngIf="client.person.typePerson === 'FOREIGN_PERSON'"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="client.person.naturalPerson.documentNumber"
                        [dropSpecialCharacters]="true"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input [label]="(client.ruralProducer) ? 'Inscrição Estadual' : 'Documento'"
                        id="clientDocumentNumberNaturalPerson"
                        name="clientDocumentNumberNaturalPerson"
                        styleClassFormField="input-checkbox-in-input"
                        *ngIf="client.person.typePerson !== 'FOREIGN_PERSON'"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [dropSpecialCharacters]="true"
                        [(ngModel)]="client.person.naturalPerson.documentNumber"
                        [max]="20"
                        [maxlength]="20"
                        fxFlex
                        fxFlex.lt-md="100">
                <ng-template #suffix
                             *ngIf="!loading">
                    <div class="hover-document">
                        <gipi-input-checkbox label="Produtor rural"
                                             id="clientRuralProducer"
                                             name="clientRuralProducer"
                                             [disabled]="loading || (typeOperation === 'VIEW')"
                                             [(ngModel)]="client.ruralProducer">
                        </gipi-input-checkbox>
                    </div>
                </ng-template>
            </itss-input>
        </div>

        <!-- Nome e Nome fantasia -->
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <itss-input [label]="(client.person.typePerson === 'LEGAL_PERSON') ? 'Razão social' : 'Nome'"
                        id="clientNamePerson"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="client.person.name"
                        (focusout)="setNameInFantasyName()"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input [label]="(client.person.typePerson === 'LEGAL_PERSON') ? 'Nome fantasia' : 'Como você quer ser chamado(a)?'"
                        id="clientFantasyNamePerson"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="client.person.fantasyName"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>

        <!-- CEP, Endereço e Número -->
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <gipi-input-select-paged label="País"
                                     id="clientCountryAddress"
                                     name="clientCountryAddress"
                                     *ngIf="client.person.typePerson === 'FOREIGN_PERSON'"
                                     [required]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW')"
                                     [property]="'name'"
                                     [nextBatchFn]="countryFindByValueFn"
                                     [(model)]="client.person.addressList[0].country"
                                     (selectionChange)="setCountryCode(client.person.addressList[0])"
                                     [fxFlex]="client.person.typePerson !== 'FOREIGN_PERSON' ? 20 : ''"
                                     fxFlex.lt-md="100">
            </gipi-input-select-paged>
            <itss-input label="CEP"
                        id="clientZipCodeAddress"
                        name="clientZipCodeAddress"
                        [required]="true"
                        [placeholder]="client.person.typePerson !== 'FOREIGN_PERSON' ? '00.000-000' : ''"
                        [mask]="client.person.typePerson !== 'FOREIGN_PERSON' ? '00.000-000' : ''"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="client.person.addressList[0].zipCode"
                        (keydown.enter)="findZipCode(client.person.addressList[0])"
                        [fxFlex]="client.person.typePerson !== 'FOREIGN_PERSON' ? 20 : ''"
                        fxFlex.lt-md="100">
                <ng-template #suffix
                             *ngIf="client.person.typePerson !== 'FOREIGN_PERSON'">
                    <div fxLayout="row"
                         fxLayoutGap="5px">
                        <gipi-button tooltip="Buscar CEP"
                                     gipi-icon
                                     id="btnSearchCepAddress"
                                     svgIcon="search"
                                     [forInput]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW')"
                                     (click)="findZipCode(client.person.addressList[0])">
                        </gipi-button>
                    </div>
                </ng-template>
            </itss-input>

            <itss-input label="Endereço"
                        id="clientStreetAddress"
                        name="clientStreetAddress"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="client.person.addressList[0].street"
                        [fxFlex]="client.person.typePerson === 'FOREIGN_PERSON' ? 49.4 : ''"
                        fxFlex.lt-md="100">
            </itss-input>

            <itss-input label="Número"
                        id="clientStreetNumberAddress"
                        name="clientStreetNumberAddress"
                        styleClassFormField="input-checkbox-in-input"
                        *ngIf="client.person.typePerson !== 'FOREIGN_PERSON'"
                        [disabled]="loading || (typeOperation === 'VIEW') || client.person.addressList[0].withoutStreetNumber"
                        [required]="true"
                        [(ngModel)]="client.person.addressList[0].streetNumber"
                        [min]="2"
                        [max]="6"
                        [maxlength]="6"
                        (ngModelChange)="validateStreetNumber($event, client.person.addressList[0])"
                        fxFlex="20"
                        fxFlex.lt-md="100">
                <ng-template #suffix
                             *ngIf="!loading && (typeOperation !== 'VIEW')">
                    <div class="hover-street-number">
                        <gipi-input-checkbox label="Sem número"
                                             id="clientWithoutStreetNumberAddress"
                                             name="clientWithoutStreetNumberAddress"
                                             [disabled]="loading"
                                             [(ngModel)]="client.person.addressList[0].withoutStreetNumber"
                                             (change)="onChangeStreetNumber($event, client.person.addressList[0])">
                        </gipi-input-checkbox>
                    </div>
                </ng-template>
            </itss-input>
        </div>

        <!-- Bairro, Estado e Cidade -->
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <gipi-input-select-paged label="Estado"
                                     id="clientStateAddress"
                                     name="clientStateAddress"
                                     *ngIf="client.person.typePerson !== 'FOREIGN_PERSON'"
                                     [showClear]="true"
                                     [required]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW')"
                                     [property]="'acronym'"
                                     [nextBatchFn]="stateFindByValueFn"
                                     [(model)]="client.person.addressList[0].state"
                                     fxFlex="20"
                                     fxFlex.lt-md="100">
            </gipi-input-select-paged>
            <gipi-input-select-paged label="Cidade"
                                     id="clientCityAddress"
                                     name="clientCityAddress"
                                     *ngIf="client.person.typePerson !== 'FOREIGN_PERSON'"
                                     [showClear]="true"
                                     [required]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW') || (!client.person.addressList[0].state)"
                                     [property]="'name'"
                                     [paramNextBatch]="client.person"
                                     [nextBatchFn]="cityFindByValueFn"
                                     [(model)]="client.person.addressList[0].city"
                                     fxFlex
                                     fxFlex.lt-md="100">
            </gipi-input-select-paged>
            <itss-input label="Bairro"
                        id="clientNeighborhoodAddress"
                        name="clientNeighborhoodAddress"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="client.person.addressList[0].neighborhood"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>

        <!-- Email e telefone -->
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <itss-input label="E-mail"
                        id="clientEmail"
                        name="clientEmail"
                        [type]="'email'"
                        [placeholder]="'exemplo@email.com'"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [lowerCase]="true"
                        [(ngModel)]="email"
                        (focusout)="validateEmail(email)"
                        [fxFlex]="client.person.typePerson === 'FOREIGN_PERSON' ? 49.4 : ''"
                        fxFlex.lt-md="100">
            </itss-input>

            <itss-input label="Celular"
                        id="clientCell"
                        name="clientCell"
                        [placeholder]="'(00) 0 0000-0000'"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [mask]="'(00) 0000-0000||(00) 0 0000-0000'"
                        [(ngModel)]="phoneOne"
                        [fxFlex]="client.person.typePerson !== 'FOREIGN_PERSON' ? 20 : ''"
                        fxFlex.lt-md="100">
            </itss-input>

            <itss-input label="Telefone"
                        id="clientPhone"
                        name="clientPhone"
                        [placeholder]="'(00) 0 0000-0000'"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [mask]="'(00) 0000-0000||(00) 0 0000-0000'"
                        [(ngModel)]="phoneTwo"
                        [fxFlex]="client.person.typePerson !== 'FOREIGN_PERSON' ? 20 : ''"
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close(null)"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>