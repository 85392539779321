import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';

import * as moment_ from 'moment';
const moment = moment_;

import { FinancialCompany } from '@gipi-financial/company/models/company.model';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { NotificationCustomerNotificationConfig } from '@gipi-notification/customer-notification-config/models/customer-notification-config.model';
import { NotificationCustomerNotificationConfigService } from '@gipi-notification/customer-notification-config/services/customer-notification-config.service';
import { NotificationNotificationStatementDTO } from '@gipi-notification/invoice-integrations/models/dto/notification-statement.dto';
import { NotificationInvoiceIntegrationsService } from '@gipi-notification/invoice-integrations/services/invoice-integrations.service';
import { RegistrationConnectConfig } from '@gipi-registration/connect-config/models/connect-config.model';
import { RegistrationConnectConfigService } from '@gipi-registration/connect-config/services/connect-config.service';
import { OptionActiveSituation, OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { APP_MESSAGES, ArrayUtil, EmailUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, InputComponent, MonthYear, ObjectUtil, StringUtil, TokenDTO } from '@gipisistemas/ng-core';

type Options = {
    id: string;
    key: string;
    title: string;
    description: string;
    selected: boolean;
};

@Component({
    templateUrl: './customer-notification-config-dialog.component.html',
    styleUrls: ['./customer-notification-config-dialog.component.scss'],
})
export class CustomerNotificationConfigDialogComponent extends GIPIAbstractComponent implements OnInit {

    @ViewChild('panelConnectInputPassword', { static: false, read: InputComponent }) panelConnectInputPasswordRef: InputComponent;

    private _now: Date = new Date()

    // Twilio
    public customerNotificationConfig: NotificationCustomerNotificationConfig = new NotificationCustomerNotificationConfig();

    public panelTwilioOptions: Options[] = [
        // { key: 'accountPayableNotificationEnabled', title: 'Contas a pagar', description: 'Envie mensagens em massa na tela contas a pagar.', selected: false },
        { id: 'panelTwilioAccountReceivable', key: 'accountReceivableNotificationEnabled', title: 'Contas a receber', description: 'Envie mensagens em massa na tela contas a receber.', selected: false },
        { id: 'panelTwilioBirthday', key: 'birthdayNotificationEnabled', title: 'Aniversários', description: 'Envie mensagens para aniversariantes do mês.', selected: false },
        { id: 'panelTwilioInactiveCustomer', key: 'potentialClientNotificationEnabled', title: 'Clientes potenciais', description: 'Envie mensagens para clientes que nunca compraram.', selected: false },
        { id: 'panelTwilioBilletKey', key: 'billetKeyNotificationEnabled', title: 'Boletos', description: 'Envie mensagens com chaves de pagamento de boletos.', selected: false },
        // { key: 'billetReminderNotificationEnabled', title: 'Lembretes de pagamento', description: 'Envie lembretes de pagamento via WhatsApp.', selected: false },
        // { key: 'promotionsAndSuggestionsNotificationEnabled', title: 'Promoções', description: 'Envie mensagens promocionais para os clientes.', selected: false },
    ];

    public panelTwilioPeriodicity: MonthYear = new MonthYear(this._now.getMonth(), this._now.getFullYear());

    public isClosedInvoiceTwilio: boolean = false;

    public panelTwilioNotificationStatement: NotificationNotificationStatementDTO = null;

    // Connect
    public connectConfig: RegistrationConnectConfig = new RegistrationConnectConfig();

    public panelConnectOptionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public panelConnectOptionActiveSituationValue: OptionActiveSituation = 'DISABLED';

    public panelConnectBtnViewPassword: boolean = false;

    public get pannelConnectIsActive(): Observable<boolean> {
        return of(this.panelConnectOptionActiveSituationValue === 'ENABLED');
    }

    public get panelTwilioStatusInvoice(): Observable<string> {
        if (ObjectUtil.isNull(this.panelTwilioNotificationStatement)) {
            return of('');
        }
        return of((this.panelTwilioNotificationStatement.quantity > 0) ? (this.panelTwilioNotificationStatement.isClosed ? 'Pago' : 'Em aberto') : '');
    }

    public get panelConnectIsNewRecord(): Observable<boolean> {
        return of(ObjectUtil.isNull(this.connectConfig) || ObjectUtil.isNewModel(ObjectUtil.isNewModel(this.connectConfig)));
    }

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _renderer2: Renderer2,
        private _authenticationService: CustomAuthenticationService,
        private _companyService: FinancialCompanyService,
        private _invoiceIntegrationsService: NotificationInvoiceIntegrationsService,
        private _customerNoficationConfigService: NotificationCustomerNotificationConfigService,
        private _connectConfigService: RegistrationConnectConfigService,
        public dialogRef: MatDialogRef<CustomerNotificationConfigDialogComponent>,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
        this._findCustomerNotificationConfig();
        this._findConnectConfig();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    private _findCustomerNotificationConfig(): void {
        try {
            this.loading = true;
            this._customerNoficationConfigService.findAllEnabled<NotificationCustomerNotificationConfig>(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this.customerNotificationConfig = ObjectUtil.clone(page.content[0]);

                    for (const option of this.panelTwilioOptions) {
                        option.selected = this.customerNotificationConfig[option.key];
                    }

                    // this.findNotificationStatement();
                } else {
                    this._setCnpjCompany();
                }
                this.loading = false;
            }).catch(e => {
                this.loading = false;
                this.handleError(e);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _setCnpjCompany(): void {
        if (ObjectUtil.isNull(this.customerNotificationConfig)) {
            return;
        }

        const token: TokenDTO<OAuthUser> = this._authenticationService.tokenValue;
        if (this._authenticationService.isValidToken(token)) {
            this._companyService.getOne<FinancialCompany>(token.user.currentCompany).subscribe(company => {
                if (!ObjectUtil.isNull(company) && !ObjectUtil.isNull(company.person)) {
                    const cpfOrCnpj: string = !ObjectUtil.isNull(company.person.legalPerson)
                        ? company.person.legalPerson.cnpj
                        : company.person.naturalPerson.cpf;

                    this.customerNotificationConfig.cnpj = cpfOrCnpj;

                    if (!ArrayUtil.isEmpty(company.person.emailList)) {
                        this.customerNotificationConfig.emailCustomer = company.person.emailList[0].email;
                    }
                }
            }, error => {
                this.loading = false;
                this.addErrorMessage(error);
                return null;
            });
        }
    }

    private _findConnectConfig(): void {
        try {
            this.loading = true;

            if (!ObjectUtil.isNull(this.connectConfig)) {
                this.connectConfig.version = 'V1';
            }

            this.panelConnectOptionActiveSituationValue = 'ENABLED';

            this._connectConfigService.findAllEnabled<RegistrationConnectConfig>(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this.connectConfig = ObjectUtil.clone(page.content[0]);
                    this.panelConnectOptionActiveSituationValue = (this.connectConfig.enabled ? 'ENABLED' : 'DISABLED');
                }
                this.loading = false;
            }).catch(e => {
                this.loading = false;
                this.handleError(e);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _isValid(): boolean {
        if (ObjectUtil.isNull(this.customerNotificationConfig)) {
            return false;
        }
        if (StringUtil.isEmpty(this.customerNotificationConfig.emailCustomer)) {
            this.addWarningMessage('Campo e-mail é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.customerNotificationConfig.phoneNumber)) {
            this.addWarningMessage('Campo e-mail é obrigatório e não foi informado');
            return false;
        }
        if (this.panelConnectOptionActiveSituationValue === 'ENABLED') {
            if (StringUtil.isEmpty(this.connectConfig.idClient)) {
                this.addWarningMessage('Campo ID Client é obrigatório e não foi informado');
                return false;
            }
            if (StringUtil.isEmpty(this.connectConfig.login)) {
                this.addWarningMessage('Campo login é obrigatório e não foi informado');
                return false;
            }
            if (StringUtil.isEmpty(this.connectConfig.password)) {
                this.addWarningMessage('Campo password é obrigatório e não foi informado');
                return false;
            }
        }
        return true;
    }

    public confirm(): void {
        try {
            if (!this._isValid()) {
                return;
            }
            this.loading = true;

            for (const option of this.panelTwilioOptions) {
                this.customerNotificationConfig[option.key] = option.selected;
            }

            // Passa null pra sempre gerar uma nova configuração
            this.customerNotificationConfig.id = null;

            this.connectConfig.enabled = (this.panelConnectOptionActiveSituationValue === 'ENABLED');
            this.connectConfig.version = 'V1';

            this.customerNotificationConfig.accountPayableNotificationEnabled = false;
            // this.customerNotificationConfig.accountReceivableNotificationEnabled = false;
            // this.customerNotificationConfig.birthdayNotificationEnabled = false;
            // this.customerNotificationConfig.potentialClientNotificationEnabled = false;
            // this.customerNotificationConfig.billetKeyNotificationEnabled = false;
            this.customerNotificationConfig.billetReminderNotificationEnabled = false;
            this.customerNotificationConfig.promotionsAndSuggestionsNotificationEnabled = false;

            forkJoin([
                this._customerNoficationConfigService.save(this.customerNotificationConfig).toPromise(),
                this._connectConfigService.save(this.connectConfig).toPromise(),
            ]).toPromise().then(responseList => {
                if (!ArrayUtil.isEmpty(responseList)) {
                    this.loading = false;
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);

                    if (ObjectUtil.isNull(this._customerNoficationConfigService.updated$)) {
                        this._customerNoficationConfigService.updated$ = new BehaviorSubject(false);
                    }
                    this._customerNoficationConfigService.updated$.next(true);

                    if (ObjectUtil.isNull(this._connectConfigService.updated$)) {
                        this._connectConfigService.updated$ = new BehaviorSubject(false);
                    }
                    this._connectConfigService.updated$.next(true);

                    this.close();
                }
            }).catch(e => {
                this.loading = false;
                this.handleError(e);
            });

            // this._customerNoficationConfigService.save(this.customerNotificationConfig).toPromise().then(_ => {
            //     this.loading = false;
            //     this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);

            //     if (ObjectUtil.isNull(this._customerNoficationConfigService.updated$)) {
            //         this._customerNoficationConfigService.updated$ = new BehaviorSubject(false);
            //     }
            //     this._customerNoficationConfigService.updated$.next(true);

            //     this.close();
            // }).catch(e => {
            //     this.loading = false;
            //     this.handleError(e);
            // });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public close(): void {
        this.dialogRef.close();
    }

    public setCaretEndNumber(event: KeyboardEvent, phoneNumber: InputComponent): void {
        const keysPermitted: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        if (keysPermitted.indexOf(event.key) >= 0) {
            phoneNumber._elementRef.nativeElement.setSelectionRange(100000, 100000);
        }
    }

    public validateEmail(email: string): void {
        if (!StringUtil.isEmpty(email) && !EmailUtil.isValid(email)) {
            this.addWarningMessage('O e-mail informado é inválido');
        }
    }

    public findNotificationStatement(): void {
        if (ObjectUtil.isNull(this.customerNotificationConfig)) {
            this.panelTwilioNotificationStatement = null;
            return;
        }

        this.loading = true;
        const invoiceDate: moment.Moment = moment().year(this.panelTwilioPeriodicity.year).month(this.panelTwilioPeriodicity.month);
        if (invoiceDate && invoiceDate.isValid()) {
            const startDate: Date = invoiceDate.startOf('month').startOf('day').toDate();
            const endDate: Date = invoiceDate.endOf('month').endOf('day').toDate();

            this._invoiceIntegrationsService.findNotificationStatement(startDate, endDate, this.isClosedInvoiceTwilio).toPromise()
                .then(notificationStatement => {
                    if (!ObjectUtil.isNull(notificationStatement)) {
                        this.panelTwilioNotificationStatement = notificationStatement;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.handleError(error);
                });
        }
    }

    public setAutoCompleteInPassword(): void {
        if (!ObjectUtil.isNull(this.panelConnectInputPasswordRef)) {
            this._renderer2.setAttribute(this.panelConnectInputPasswordRef._elementRef.nativeElement, 'autocomplete', 'new-password');
        }
    }

}
