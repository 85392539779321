import { AuthenticationService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class ShepherdVersionService {
    constructor(_authenticationService) {
        this._authenticationService = _authenticationService;
        this._tourKey = `tour-${this._getCurrentUserId()}`;
        this._tourVersion = 'v1.0'; // Versão atual do tour, altere para 'v2.0' se houver um tour novo
    }
    _getCurrentUserId() {
        if (this._authenticationService.isValidToken(this._authenticationService.tokenValue)) {
            const currentUser = this._authenticationService.tokenValue.user;
            return !ObjectUtil.isNull(currentUser) ? currentUser.id : null;
        }
        return null;
    }
    _getTourStoraged() {
        const tourStoragedString = localStorage.getItem(this._tourKey);
        if (!StringUtil.isEmpty(tourStoragedString)) {
            try {
                const decript = atob(tourStoragedString);
                return JSON.parse(decript);
            }
            catch (error) {
                return null;
            }
        }
        return null;
    }
    tourIsViewed() {
        const tourStoraged = this._getTourStoraged();
        const currentUser = this._getCurrentUserId();
        const isTourStoragedValid = (!ObjectUtil.isNull(tourStoraged) && (tourStoraged.version === this._tourVersion) && (tourStoraged.id === currentUser));
        if (ObjectUtil.isNull(tourStoraged) || !isTourStoragedValid) {
            return false;
        }
        return tourStoraged.completed;
    }
    saveTourState(tourCompleted) {
        const tourState = {
            id: this._getCurrentUserId(),
            version: this._tourVersion,
            completed: tourCompleted,
        };
        const cript = btoa(JSON.stringify(tourState));
        localStorage.setItem(this._tourKey, cript);
    }
    resetTour() {
        localStorage.removeItem(this._tourKey);
    }
}
ShepherdVersionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShepherdVersionService_Factory() { return new ShepherdVersionService(i0.ɵɵinject(i1.AuthenticationService)); }, token: ShepherdVersionService, providedIn: "root" });
