import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FinancialReceivementCard } from '@gipi-financial/card-administrator/models/receivement-card.model';
import { FinancialCheckReceived } from '@gipi-financial/check-received/models/check-received.model';
import { FinancialCheckStatusEnum } from '@gipi-financial/check/enums/check-status.enum';
import { FinancialReceivement } from '@gipi-financial/receivement/models/receivement.model';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ArrayUtil, ConfirmationService, CurrencyUtil, GIPIUuid, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { FinancialCancelPayment } from '../models/cancel-payment.model';
import { FinancialCancelCheckDTO } from '../models/dto/cancel-check.dto';
import { FinancialCancelPaymentFilterDTO } from '../models/dto/cancel-payment-filter.dto';
import { FinancialCancelPaymentDTO } from '../models/dto/cancel-payment.dto';
import { FinancialPaymentByCheckDTO } from '../models/dto/payment-by-check.dto';
import { FinancialPaymentCheckReceivedDTO } from '../models/dto/payment-check-received.dto';

@Injectable({ providedIn: 'root' })
export class FinancialCancelPaymentService extends BaseCrudService<FinancialCancelPayment, FinancialCancelPaymentFilterDTO> {

    constructor(
        private _receivementService: FinancialReceivementService,
        private _confirmationService: ConfirmationService,
        http: HttpClient,
        authenticationService: CustomAuthenticationService,
    ) {
        super(FinancialPaths.cancelPayment, http, authenticationService);
    }

    cancel(cancelPayment: FinancialCancelPaymentDTO, billTypeEnum: string): Observable<FinancialPaymentCheckReceivedDTO[]> {
        const url: string = `cancel/${billTypeEnum}`;
        return this.http.post(this.url(url), cancelPayment, this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    numberDocumentPaymentByCheck(installmentIdList: GIPIUuid[]): Observable<FinancialPaymentByCheckDTO> {
        if (ArrayUtil.isEmpty(installmentIdList)) {
            return of(null);
        }
        const installmentList: { id: GIPIUuid }[] = installmentIdList.map(id => { return { id: id } });
        return this.http.post(this.url('number-document-payment-by-check'), installmentList, this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    private async _verifyReceivementsByCardOrCheckReceived(receivementList: FinancialReceivement[]): Promise<boolean> {
        try {
            const listMessages: string[] = [];
            let typeReceivementMessage: string = '';

            const receivementCardList: FinancialReceivementCard[] = [];
            receivementList.filter(r => ((r.chargeType.type === 'CREDIT_CARD') || (r.chargeType.type === 'DEBIT_CARD')) && (!ArrayUtil.isEmpty(r.receivementCardList))).forEach(r => receivementCardList.push(...r.receivementCardList));

            const receivementCheckReceivedList: FinancialCheckReceived[] = [];
            receivementList.filter(r => (r.chargeType.type === 'CHECK') && (!ArrayUtil.isEmpty(r.checkReceivedList))).forEach(r => receivementCheckReceivedList.push(...r.checkReceivedList));

            if (!ArrayUtil.isEmpty(receivementCardList)) {
                const receivementCardReceiptList: FinancialReceivementCard[] = receivementCardList.filter(rc => rc.status === 'RECEIVED');
                if (!ArrayUtil.isEmpty(receivementCardReceiptList)) {
                    for (let i: number = 0; i < receivementCardReceiptList.length; i++) {
                        const cardAdministrator: string = receivementCardReceiptList[i].cardAdministrator.description;
                        const flagCard: string = receivementCardReceiptList[i].flagCard.description;
                        const amount: string = CurrencyUtil.transform(receivementCardReceiptList[i].amountCard, '1.2-2');
                        const message: string = `Conta a receber da adm. de cartão ${cardAdministrator} bandeira ${flagCard} valor ${amount}`;
                        listMessages.push(message);
                    }

                    typeReceivementMessage = 'cartão de crédito ou débito';
                }
            }

            if (!ArrayUtil.isEmpty(receivementCheckReceivedList)) {
                const checkReceivedReceiptList: FinancialCheckReceived[] = receivementCheckReceivedList.filter(cr => cr.check.status !== 'OPENED');
                if (!ArrayUtil.isEmpty(checkReceivedReceiptList)) {
                    for (let i: number = 0; i < checkReceivedReceiptList.length; i++) {
                        const checkNumber: string = checkReceivedReceiptList[i].check.checkNumber.toString();
                        const nameClient: string = checkReceivedReceiptList[i].client.person.name;
                        const statusCheck: string = FinancialCheckStatusEnum[checkReceivedReceiptList[i].check.status];
                        const message: string = `Cheque n° "${checkNumber}", recebido de ${nameClient}, com status "${statusCheck.toLowerCase()}"`;
                        listMessages.push(message);
                    }

                    typeReceivementMessage = (typeReceivementMessage !== '' ? (typeReceivementMessage + ' e ') : '') + 'cheque';
                }
            }

            if (listMessages.length > 0) {
                await this._confirmationService.confirm({
                    title: 'Aviso',
                    message: `Este recebimento foi realizado com ${typeReceivementMessage}, para prosseguir você deverá realizar o cancelamento dos seguintes documentos:`,
                    width: '30%',
                    type: 'warning',
                    listMessage: listMessages
                });
                return Promise.resolve(true);
            } else {
                return Promise.resolve(false);
            }
        } catch (e) {
            throw new Error(e);
        }
    }

    private async _verifyLinkedBillsReceived(receivementList: FinancialReceivement[]): Promise<boolean> {
        try {
            const listMessages: string[] = [];
            let typeReceivementMessage: string = '';
            const receivementLinkedList: FinancialReceivement[] = [];
            const receivementCardList: FinancialReceivementCard[] = [];
            const receivementCheckReceivedList: FinancialCheckReceived[] = [];

            for (let i: number = 0; i < receivementList.length; i++) {
                if (!ArrayUtil.isEmpty(receivementList[i].receivementCardList) || !ArrayUtil.isEmpty(receivementList[i].checkReceivedList)) {
                    const list: FinancialReceivement[] = await this._receivementService.findLinkedReceivements(receivementList[i].id).toPromise();
                    receivementLinkedList.push(...list);

                    if (!ArrayUtil.isEmpty(receivementList[i].receivementCardList)) {
                        receivementCardList.push(...receivementList[i].receivementCardList);
                    }

                    if (!ArrayUtil.isEmpty(receivementList[i].checkReceivedList)) {
                        receivementCheckReceivedList.push(...receivementList[i].checkReceivedList);
                    }
                }
            }

            if (!ArrayUtil.isEmpty(receivementLinkedList)) {
                for (let i: number = 0; i < receivementLinkedList.length; i++) {
                    const documentNumber: string = receivementLinkedList[i].billInstallment.documentNumber
                        ? `de documento n° "${receivementLinkedList[i].billInstallment.documentNumber}"`
                        : '';

                    const amount: string = CurrencyUtil.transform(receivementLinkedList[i].billInstallment.amount, '1-2.2');

                    const message: string = `Conta ${documentNumber} no valor de ${amount}`;
                    listMessages.push(message);
                }

                if (!ArrayUtil.isEmpty(receivementCardList)) {
                    typeReceivementMessage = 'cartão de crédito ou débito';
                }

                if (!ArrayUtil.isEmpty(receivementCheckReceivedList)) {
                    typeReceivementMessage = (typeReceivementMessage !== '' ? (typeReceivementMessage + ' e ') : '') + 'cheque';
                }
            }

            if (listMessages.length > 0) {
                const isConfirmed: boolean = await this._confirmationService.confirm({
                    title: 'Confirmação',
                    message: `Este recebimento foi realizado com ${typeReceivementMessage}. Portanto, ao confirmar o cancelamento irá estornar os seguintes documentos:`,
                    width: '30%',
                    listMessage: listMessages
                });

                return Promise.resolve(isConfirmed);
            } else {
                return Promise.resolve(true);
            }
        } catch (e) {
            throw new Error(e);
        }
    }

    /** Utilizado para contas a receber */
    public async isCancelReceivementAllowed(installmentId: GIPIUuid): Promise<boolean> {
        try {
            const receivementList: FinancialReceivement[] = await this._receivementService.findByBillInstallment(installmentId).toPromise().catch(error => {
                throw new Error(error);
            });

            const hasReceivement: boolean = await this._verifyReceivementsByCardOrCheckReceived(receivementList);
            if (hasReceivement) {
                return Promise.resolve(false);
            } else {
                const hasLinkedBillsReceived: boolean = await this._verifyLinkedBillsReceived(receivementList);
                return Promise.resolve(hasLinkedBillsReceived);
            }
        } catch (e) {
            throw new Error(e);
        }
    }

    /** Utilizado para contas a pagar */
    public async isCancelPaymentAllowed(installmentId: GIPIUuid): Promise<boolean> {
        try {
            const paymentByCheck: FinancialPaymentByCheckDTO = await this.numberDocumentPaymentByCheck([installmentId]).toPromise().catch(error => {
                throw new Error(error);
            });

            if (ObjectUtil.isNull(paymentByCheck)) {
                return Promise.resolve(false);
            }

            if (ArrayUtil.isEmpty(paymentByCheck.checkIssuedList) && ArrayUtil.isEmpty(paymentByCheck.checkReceivedList)) {
                return Promise.resolve(true);
            }

            let listMessages: string[] = [];
            let typePaymentMessage: string = '';
            let numberDocumentPayment: string = '';

            if (paymentByCheck.checkReceivedList.length > 0) {
                if (!ArrayUtil.isEmpty(paymentByCheck.numberDocumentPaymentCheckReceived) && (paymentByCheck.numberDocumentPaymentCheckReceived.length > 1)) {
                    numberDocumentPayment = paymentByCheck.numberDocumentPaymentCheckReceived.join(', ');
                }

                const isMoreOne: string = (paymentByCheck.checkReceivedList.length > 1) ? 's' : '';
                let firstText: string = '';
                if (!StringUtil.isEmpty(numberDocumentPayment)) {
                    firstText = `A${isMoreOne} conta${isMoreOne} de documento${isMoreOne} nº ${numberDocumentPayment} ${isMoreOne === 's' ? 'foram pagas' : 'foi paga'} com cheque${isMoreOne} recebido${isMoreOne}`;
                } else {
                    firstText = `Este pagamento foi realizado com cheque${isMoreOne} recebido${isMoreOne}`;
                }
                typePaymentMessage = `${firstText}. Ao prosseguir, os seguintes documentos serão estornados e o pagamento será cancelado.`;

                for (let i: number = 0; i < paymentByCheck.checkReceivedList.length; i++) {
                    const checkNumber: string = paymentByCheck.checkReceivedList[i].checkNumber.toString();
                    const issuerName: string = paymentByCheck.checkReceivedList[i].issuerName;
                    const statusCheck: string = FinancialCheckStatusEnum[paymentByCheck.checkReceivedList[i].status].toLowerCase();
                    const message: string = `Cheque n° "${checkNumber}", recebido de ${issuerName}, com status "${statusCheck}"`;
                    listMessages.push(message);
                }

                if (listMessages.length > 0) {
                    const isConfirmed: boolean = await this._confirmationService.confirm({
                        title: 'Aviso',
                        message: typePaymentMessage,
                        width: '30%',
                        type: 'warning',
                        listMessage: listMessages,
                    });

                    return Promise.resolve(isConfirmed);
                } else {
                    return Promise.resolve(true);
                }

            } else if ((paymentByCheck.checkIssuedList.length > 0) || (paymentByCheck.checkWithMovementList.length > 0)) {
                if (!ArrayUtil.isEmpty(paymentByCheck.numberDocumentPaymentCheckIssued) && (paymentByCheck.numberDocumentPaymentCheckIssued.length > 1)) {
                    numberDocumentPayment = paymentByCheck.numberDocumentPaymentCheckIssued.join(', ');
                }

                const isMoreOne: string = (paymentByCheck.checkIssuedList.length > 1) ? 's' : '';
                let firstText: string = '';
                if (!StringUtil.isEmpty(numberDocumentPayment)) {
                    firstText = `A${isMoreOne} conta${isMoreOne} de documento${isMoreOne} nº ${numberDocumentPayment} ${isMoreOne === 's' ? 'foram pagas' : 'foi paga'} com cheque${isMoreOne} emitido${isMoreOne}`;
                } else {
                    firstText = `Este pagamento foi realizado com cheque${isMoreOne} emitido${isMoreOne}`;
                }

                const checkIssuedCompensatedList: FinancialCancelCheckDTO[] = paymentByCheck.checkWithMovementList.filter(e => e.status === 'COMPENSATED');
                if (!ArrayUtil.isEmpty(checkIssuedCompensatedList)) {
                    typePaymentMessage = `${firstText}, para prosseguir você deverá realizar o cancelamento dos seguintes documentos:`;

                    for (let i: number = 0; i < checkIssuedCompensatedList.length; i++) {
                        const checkNumber: string = checkIssuedCompensatedList[i].checkNumber.toString();
                        const issuerName: string = checkIssuedCompensatedList[i].issuerName;
                        const statusCheck: string = FinancialCheckStatusEnum[checkIssuedCompensatedList[i].status].toLowerCase();
                        const message: string = `Cheque n° "${checkNumber}", emitido por ${issuerName}, com status "${statusCheck}"`;
                        listMessages.push(message);
                    }
                } else {
                    typePaymentMessage = `${firstText}. Ao prosseguir, os seguintes documentos serão estornados e o pagamento será cancelado.`;

                    for (let i: number = 0; i < paymentByCheck.checkIssuedList.length; i++) {
                        const checkNumber: string = paymentByCheck.checkIssuedList[i].checkNumber.toString();
                        const issuerName: string = paymentByCheck.checkIssuedList[i].issuerName;
                        const statusCheck: string = FinancialCheckStatusEnum[paymentByCheck.checkIssuedList[i].status].toLowerCase();
                        const message: string = `Cheque n° "${checkNumber}", emitido por ${issuerName}, com status "${statusCheck}"`;
                        listMessages.push(message);
                    }
                }

                if (listMessages.length > 0) {
                    const isConfirmed = await this._confirmationService.confirm({
                        title: 'Aviso',
                        message: typePaymentMessage,
                        width: '30%',
                        type: 'warning',
                        listMessage: listMessages,
                    });

                    const result: boolean = !ArrayUtil.isEmpty(checkIssuedCompensatedList) ? false : isConfirmed;
                    return Promise.resolve(result);
                } else {
                    return Promise.resolve(true);
                }
            } else {
                return Promise.resolve(true);
            }

        } catch (e) {
            throw new Error(e);
        }
    }

}
