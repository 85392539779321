import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { FinancialReceivementCard } from '@gipi-financial/card-administrator/models/receivement-card.model';
import { FinancialCardAdministratorService } from '@gipi-financial/card-administrator/services/card-administrator.service';
import { FinancialFlagCardService } from '@gipi-financial/flag-card/services/flag-card.service';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { APP_MESSAGES, ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, NumberUtil, ObjectUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class ReceivementCardDialogComponent extends GIPIAbstractComponent {
    constructor(baseService, activatedRoute, _changeDetectorRef, _cardAdministratorService, _flagCardService, _receivementService, dialogRef, data = null) {
        super(baseService, activatedRoute);
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._changeDetectorRef = _changeDetectorRef;
        this._cardAdministratorService = _cardAdministratorService;
        this._flagCardService = _flagCardService;
        this._receivementService = _receivementService;
        this.dialogRef = dialogRef;
        this.data = data;
        this._receivement = null;
        this.receivementCardList = [];
        this.columns = [];
        this.receivementCard = this._newReceivementCard();
        this.quantityInstallment = 1;
        this.optionAmountCard = { allowNegative: false, min: 0, max: this.amountRemaining };
        this.cardAdministratorFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._cardAdministratorService.findByValue(value, page, 10).toPromise();
            return result;
        });
        this.flagCardFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._flagCardService.findByValue(value, page, 10).toPromise();
            return result;
        });
    }
    get amountRemaining$() {
        return of(this.amountRemaining);
    }
    get isValidAddReceivementCard$() {
        if (ObjectUtil.isNull(this.receivementCard)) {
            return of(false);
        }
        return of(!ObjectUtil.isNull(this.receivementCard.cardAdministrator) &&
            !ObjectUtil.isNull(this.receivementCard.flagCard) &&
            NumberUtil.isPositive(this.receivementCard.amountCard) &&
            (this.quantityInstallment >= 1));
    }
    get amountToReceive() {
        const receivementAux = !ObjectUtil.isNull(this._receivement) ? this._receivement : this.data.receivement;
        return (receivementAux.amountReceived ? receivementAux.amountReceived : receivementAux.amountReceivable);
    }
    get amountAdded() {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            return 0;
        }
        const amountAdded = this.receivementCardList.reduce((sum, e) => sum += e.amountCard, 0);
        return amountAdded;
    }
    get amountRemaining() {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            return this.amountToReceive;
        }
        return (this.amountToReceive - this.amountAdded);
    }
    ngOnInit() {
        super.ngOnInit();
        this.columns = this._createTableColumns();
        if (!ObjectUtil.isNull(this.data)) {
            this._receivement = ObjectUtil.clone(this.data.receivement);
            this._receivement.amountNet = this._receivement.amountReceivable;
        }
        this._changeDetectorRef.markForCheck();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    _createTableColumns() {
        return [
            TableColumnBuilder.instance()
                .property('numberInstallment')
                .name('N°')
                .value((obj) => ('00' + obj.numberInstallment).slice(-2))
                .width(5)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('issuanceDate')
                .name('Emissão')
                .value((obj) => DateUtil.format(obj.issuanceDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .value((obj) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('cardAdministrator')
                .name('Administradora de cartão')
                .value((obj) => obj.cardAdministrator.description)
                .marginLeft(15)
                .build(),
            TableColumnBuilder.instance()
                .property('flagCard')
                .name('Bandeira do cartão')
                .value((obj) => obj.flagCard.description)
                .build(),
            TableColumnBuilder.instance()
                .property('amountCard')
                .name('Valor')
                .value((obj) => CurrencyUtil.transform(obj.amountCard, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .template(this.actions)
                .align('center center')
                .width(10)
                .build(),
        ];
    }
    _newReceivementCard() {
        const entity = new FinancialReceivementCard();
        entity.chargeType = ObjectUtil.clone(this.data.receivement.chargeType);
        entity.bankAccount = ObjectUtil.clone(this.data.receivement.bankAccount);
        entity.cardAdministrator = null;
        entity.flagCard = null;
        entity.issuanceDate = new Date();
        entity.dueDate = new Date();
        entity.status = 'RECEIVABLE';
        entity.amountCard = this.amountRemaining;
        return entity;
    }
    _getOrderId() {
        const orderId = this.receivementCardList.map(e => e.orderId);
        if (orderId.length > 0) {
            return Math.max(...orderId) + 1;
        }
        return 1;
    }
    _validateAddReceivementCard() {
        if (ObjectUtil.isNull(this.receivementCard.cardAdministrator)) {
            this.addWarningMessage('Campo administradora de cartão é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.receivementCard.flagCard)) {
            this.addWarningMessage('Campo bandeira do cartão é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.quantityInstallment)) {
            this.addWarningMessage('Campo quantidade de parcelas é obrigatório e não foi informado');
            return false;
        }
        const amountAddedAux = this.amountAdded;
        const amountToReceiveAux = this.amountToReceive;
        if (amountAddedAux === amountToReceiveAux) {
            this.addWarningMessage(`O valor lançado R$ ${(amountAddedAux).toFixed(2)} já corresponde ao valor total do recebimento`);
            return false;
        }
        if ((this.receivementCard.amountCard + amountAddedAux) > amountToReceiveAux) {
            this.addWarningMessage(`O valor que você está tentando lançar R$ ${(amountAddedAux).toFixed(2)} ultprassa o valor total do recebimento`);
            return;
        }
        if (!NumberUtil.isPositive(this.receivementCard.amountCard)) {
            this.addWarningMessage('Campo valor é obrigatório e não foi informado');
            return false;
        }
        if (!DateUtil.isValid(this.receivementCard.issuanceDate)) {
            this.addWarningMessage('Campo data da emissão é obrigatório e não foi informado');
            return false;
        }
        if ((this.quantityInstallment > 1) && ((this.receivementCard.amountCard / this.quantityInstallment) < 1)) {
            this.addWarningMessage('Não é possível gerar parcelas com valor abaixo de R$ 1,00');
            return false;
        }
        return true;
    }
    addReceivementCard() {
        try {
            if (!this._validateAddReceivementCard()) {
                return;
            }
            this.loading = true;
            const receivementCardListAux = ArrayUtil.clone(this.receivementCardList);
            const now = new Date();
            const orderId = this._getOrderId();
            const amountPortion = (this.receivementCard.amountCard / this.quantityInstallment);
            const daysAdd = ((this.receivementCard.chargeType.type === 'CREDIT_CARD') ?
                this.receivementCard.cardAdministrator.dayCreditReceipt :
                this.receivementCard.cardAdministrator.dayDebitReceipt);
            for (let i = 0; i < this.quantityInstallment; i++) {
                const receivementCard = ObjectUtil.clone(this.receivementCard);
                receivementCard.amountCard = Number(amountPortion.toFixed(2));
                receivementCard.dueDate = new Date(now.getFullYear(), now.getMonth() + i, now.getDate() + daysAdd);
                receivementCard.numberInstallment = i + 1;
                receivementCard.orderId = orderId;
                receivementCardListAux.push(receivementCard);
            }
            const amountAddedAux = receivementCardListAux.reduce((sum, e) => sum + e.amountCard, 0);
            const amountDifference = Number(amountAddedAux.toFixed(2)) - this.receivementCard.amountCard;
            if (amountDifference > 0) {
                receivementCardListAux[0].amountCard -= amountDifference;
            }
            else if (amountDifference < 0) {
                receivementCardListAux[0].amountCard += (amountDifference * -1);
            }
            this.receivementCardList = [...receivementCardListAux];
            this.receivementCard = this._newReceivementCard();
            this.quantityInstallment = 1;
            this.loading = false;
            this._changeDetectorRef.detectChanges();
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    removeReceivementCard(entity) {
        try {
            this.loading = true;
            const receivementCardListAux = ArrayUtil.clone(this.receivementCardList);
            const indexReceivementCard = receivementCardListAux.findIndex(e => (e.orderId === entity.orderId) && (e.numberInstallment === entity.numberInstallment));
            receivementCardListAux.splice(indexReceivementCard, 1);
            const now = new Date();
            const receivementCardByOrderId = receivementCardListAux.filter(e => e.orderId === entity.orderId);
            for (let i = 0; i < receivementCardByOrderId.length; i++) {
                const daysAdd = ((receivementCardByOrderId[i].chargeType.type === 'CREDIT_CARD') ?
                    receivementCardByOrderId[i].cardAdministrator.dayCreditReceipt :
                    receivementCardByOrderId[i].cardAdministrator.dayDebitReceipt);
                receivementCardByOrderId[i].dueDate = new Date(now.getFullYear(), now.getMonth() + i, now.getDate() + daysAdd);
                receivementCardByOrderId[i].numberInstallment = i + 1;
            }
            this.receivementCardList = [...receivementCardListAux];
            this.receivementCard = this._newReceivementCard();
            this.quantityInstallment = 1;
            this.loading = false;
            this._changeDetectorRef.detectChanges();
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    _validateConfirm() {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            this.addWarningMessage('É necessário adicionar no mínimo uma parcela, para salvar o recebimento');
            return false;
        }
        const amountAddedAux = Number(this.amountAdded.toFixed(2));
        const amountToReceiveAux = this.amountToReceive;
        if (amountAddedAux < amountToReceiveAux) {
            this.addWarningMessage(`Ainda falta lançar R$ ${CurrencyUtil.transform(this.amountRemaining, '1.2-2')} para corresponder o valor total do recebimento`);
            return false;
        }
        if (amountAddedAux > amountToReceiveAux) {
            this.addWarningMessage(`O valor lançado R$ ${CurrencyUtil.transform(Number((amountAddedAux).toFixed(2)), '1.2-2')} está ultrapassando o valor total do recebimento`);
            return false;
        }
        return true;
    }
    confirm() {
        try {
            if (!this._validateConfirm()) {
                return;
            }
            this.loading = true;
            this._receivementService.receive(this._receivement, this.data.installmentList, this.receivementCardList, this.data.useClientCredit).toPromise().then(_ => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close(true);
            }).catch(error => {
                this.loading = false;
                this.addErrorMessage(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    close(isSaved) {
        this.dialogRef.close(isSaved);
    }
}
