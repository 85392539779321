
import { Injectable } from '@angular/core';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { AuthenticationService, GIPIUuid, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

interface TourStoraged {
    id: GIPIUuid; // Id do user
    version: string,
    completed: boolean;
}

@Injectable({ providedIn: 'root' })
export class ShepherdVersionService {

    private _tourKey: string = `tour-${this._getCurrentUserId()}`;
    private _tourVersion: string = 'v1.0'; // Versão atual do tour, altere para 'v2.0' se houver um tour novo

    constructor(private _authenticationService: AuthenticationService<OAuthUser>,) { }

    private _getCurrentUserId(): GIPIUuid {
        if (this._authenticationService.isValidToken(this._authenticationService.tokenValue)) {
            const currentUser: OAuthUser = this._authenticationService.tokenValue.user;
            return !ObjectUtil.isNull(currentUser) ? currentUser.id : null;
        }

        return null;
    }

    private _getTourStoraged(): TourStoraged {
        const tourStoragedString: string = localStorage.getItem(this._tourKey);
        if (!StringUtil.isEmpty(tourStoragedString)) {
            try {
                const decript: string = atob(tourStoragedString);
                return (JSON.parse(decript) as TourStoraged);
            } catch (error) {
                return null;
            }
        }

        return null;
    }

    public tourIsViewed(): boolean {
        const tourStoraged: TourStoraged = this._getTourStoraged();
        const currentUser: GIPIUuid = this._getCurrentUserId();

        const isTourStoragedValid: boolean = (!ObjectUtil.isNull(tourStoraged) && (tourStoraged.version === this._tourVersion) && (tourStoraged.id === currentUser));
        if (ObjectUtil.isNull(tourStoraged) || !isTourStoragedValid) {
            return false;
        }

        return tourStoraged.completed;
    }

    public saveTourState(tourCompleted: boolean): void {
        const tourState: TourStoraged = {
            id: this._getCurrentUserId(),
            version: this._tourVersion,
            completed: tourCompleted,
        };

        const cript: string = btoa(JSON.stringify(tourState));
        localStorage.setItem(this._tourKey, cript);
    }

    public resetTour(): void {
        localStorage.removeItem(this._tourKey);
    }

}
